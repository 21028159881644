import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import Loader from '../../components/loader/Loader';
import { Grid, Paper, styled, } from '@mui/material'
import Toptag from '../../components/topTag/Toptag';
import APIFunctions from '../../ApiFunction'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';
import { PlayCircle, PlayCircleFilledWhite, PlayCircleOutline } from '@mui/icons-material';


const BackPaper = styled(Paper)({
    height: "90vh",
    overflow: "auto",
    padding: "20px",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
});
const BottomPaper = styled(Paper)({
    height: "auto",
    width: '100%',
    marginTop: '10px',
    overflow: "auto",
    padding: "20px",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
});


const ViewVideos = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [obj, setObj] = useState({ video: "", description: "", title: "", steps: [], parts: [], tags: [] })
    const player = useRef(null);
    const id = localStorage.getItem('video_id');
    const navigate = useNavigate();
    const [clickedIndex, setClickedIndex] = useState(0);
    const [showFullDescription, setShowFullDescription] = useState(false);




    const handlePlay = () => {
        
        if (player && player.current) {
            player.current.seekTo(0); // Start the video from the beginning
            player.current.play(); // Play the video
        }
    };

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };
    const handleSeekTo = (start_time) => {
        const [minutes, seconds] = start_time.split(':').map(parseFloat);
        let time = minutes * 60 + seconds;
        if (player.current) {
            player.current.seekTo(time, 'seconds');
        }
    };

    const handleChapterClick = (startTime, index) => {
        setClickedIndex(index);
        handleSeekTo(startTime, index);
    };
    const handleProgress = ({ playedSeconds }) => {

        const { steps } = obj;
        if (steps.length > 0) {
            steps.forEach((step, index) => {
                const [minutes, seconds] = step.start_time.split(':').map(parseFloat);
                const chapterTime = minutes * 60 + seconds;
                if (playedSeconds >= chapterTime) {
                    setClickedIndex(index);
                }
            });
        }
    };

    const getAll = async () => {
        const result = await APIFunctions.getVideoDetail(id);
        console.log(result, 'result')
        if (result.data.code === 200) {
            setObj(result.data.result)
            setIsLoading(false)
        } else if (result.data.code === 203) {
            window.location.reload()
            localStorage.clear();
            navigate("/")
            toast.info("Session Expired Please login again!!")
        }
    }

    useEffect(() => {
        setIsLoading(true);

        getAll();
    }, [])



    return (
        <>

            {
                isLoading ? (
                    <Loader />) : (

                    <>
                        <Toptag />

                        <BackPaper >

                            <Grid container spacing={2} direction="row" display='flex' justifyContent="space-between" alignItems="center" >
                                <Grid xs={12} sm={12} md={6} lg={8} sx={{ maxHeight: '450px', objectFit: 'fill' }} >
                                    <Grid item >
                                    <ReactPlayer
                                    className='reactPlayer'
                                        ref={player}
                                        url={obj.video}
                                        controls
                                        width="100%"
                                        height='450px'          
                                        config={{
                                            file: {
                                                attributes: {
                                                    poster: obj.thumbnail, // Set thumbnail as video poster
                                                },
                                            },
                                        }}
                                        onProgress={handleProgress}
                                    /> 
                                    </Grid>

                                    <Grid item>

                                    <BottomPaper>
                                        <div style={{ color: 'black', fontSize: '20px', fontWeight: '600' }}>{obj.title}</div>
                                        <div style={{ color: 'grey', fontSize: '15px', marginTop: '10px', maxHeight: showFullDescription ? 'none' : '80px', overflow: 'hidden' }}>
                                            {obj.description.length > 200 ? (
                                                <>
                                                    {showFullDescription ? (
                                                        obj.description
                                                    ) : (
                                                        <>
                                                            {obj.description.slice(0, 200)}...
                                                            <span style={{ color: '#e42323', cursor: 'pointer' }} onClick={toggleDescription}> Read more</span>
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                obj.description
                                            )}
                                        </div>
                                        {showFullDescription && obj.description.length > 200 && (
                                            <div style={{ color: '#e42323', fontSize: '13px', cursor: 'pointer' }} onClick={toggleDescription}>
                                                Show less
                                            </div>
                                        )}

                                        <div style={{ display: 'flex', color: 'grey', alignItems: 'center', flexDirection: 'row', fontSize: '15px' }}>
                                            {obj.tags.map((tag, index) => (
                                                <div key={index} style={{ marginRight: '6px', marginTop: '8px' }}>{`#${tag}`}</div>
                                            ))}
                                        </div>

                                        <div style={{ display: 'flex', color: 'grey', alignItems: 'center', flexDirection: 'row', fontSize: '15px' }}>
                                           {obj.vehicle ?? ''}
                                        </div>

                                        <div style={{ display: 'flex', color: 'grey', alignItems: 'center', marginTop: '20px', flexDirection: 'row', fontSize: '15px' }}>
                                            <div style={{ marginRight: '20px' }}>{obj.views === 1 ? `${obj.views} view` : `${obj.views} views`}</div>
                                            <div>{obj.like_count === 1 ? `${obj.like_count} like` : `${obj.like_count} likes`}</div>
                                        </div>

                                    </BottomPaper>


                            
                                    </Grid>
                                    
                                        {/* <ReactPlayer
                                            url={obj.video}
                                            controls
                                            width="100%"
                                            height="auto"
                                           
                                            onStart={handlePlay} // Callback when video starts playing
                                            config={{
                                                file: {
                                                    attributes: {
                                                        poster: obj.thumbnail, // Set thumbnail as video poster
                                                    },
                                                },
                                            }}
                                        /> */}

                                </Grid>


                                <Grid xs={12} sm={12} md={6} lg={4} item sx={{ paddingTop: '0px', maxHeight: '450px' }}>
                                    <Grid item style={{height: 'auto', width: '100%', minHeight: '400px'}}>
                                    {obj.steps.length > 0 ?
                                        (<div style={{ overflowY: 'hidden', height: '100% ' }}>
                                            <div style={{ marginLeft: '5px', fontSize: '16px', fontWeight: '500' }}>CHAPTERS</div>
                                            <div style={{ overflowY: 'auto', maxHeight: '400px', border: '2px solid #d38888', backgroundColor: 'black', borderRadius: '10px', padding: '10px', margin: '5px' }}>
                                                {
                                                    obj.steps.map((step, index) => (
                                                        <div
                                                            key={index}
                                                            className={`videosChapter ${clickedIndex === index ? 'selectedChapter' : ''}`}
                                                            onClick={() => handleChapterClick(step.start_time, index)}

                                                        >

                                                            <div style={{ width: '20%' }}>
                                                                <img
                                                                    src={step.image}
                                                                    style={{ width: '60px', height: '60px' }}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div style={{ width: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'center', color: '#c1c1c1', fontSize: '14px', fontWeight: '500', marginLeft: '10px', }}>
                                                                <span>{step.guide_title}</span>
                                                                <span>{step.start_time}</span>
                                                            </div>
                                                        </div>
                                                    )
                                                    )}
                                            </div>
                                        </div>)
                                        :
                                        null}
                                    </Grid>
                                    <Grid item style={{marginTop: '20px'}}>
                                    {obj.parts.length > 0 ? (
                                        <div style={{ overflowY: 'hidden', height: '100% ', }}>
                                            <div style={{ marginLeft: '5px', fontSize: '16px', fontWeight: '500', marginTop: '10px', }}>PARTS</div>
                                            <div style={{ overflowY: 'auto', maxHeight: '150px', display: 'flex', flexDirection: 'column', border: '1px solid red', borderRadius: '10px', padding: '10px', margin: '5px' }}>

                                                {

                                                    obj.parts.map((part, index) => (
                                                        <>
                                                            <div style={{ display: 'flex', margin: "8px" }}>
                                                                <div style={{ width: '20%' }}>
                                                                    <div
                                                                        style={{
                                                                            width: '30px',
                                                                            height: '30px',
                                                                            borderRadius: '50%',
                                                                            border: '2px solid red',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            fontSize: '14px',
                                                                            fontWeight: '500',
                                                                            color: 'red'
                                                                        }}
                                                                    >{index + 1}</div>
                                                                </div>
                                                                <div style={{ width: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'center', color: '#c1c1c1', fontSize: '14px', fontWeight: '500', marginLeft: '10px', }}>
                                                                    <div style={{ fontSize: '17px', fontWeight: '400', color: 'black' }}>{part}</div>

                                                                </div>
                                                            </div>
                                                        </>
                                                    ))

                                                }

                                            </div>
                                        </div>
                                    ) : null}
                                    </Grid>
                                    



                                </Grid>

                            </Grid>

                        

                        </BackPaper >



                    </>
                )
            }
        </>


    )
}

export default ViewVideos;




