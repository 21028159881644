// import { styled } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Grid, Paper, Typography, styled, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Dialog, DialogTitle, DialogActions, Button, Slide, DialogContent, DialogContentText, TextField } from '@mui/material'
import { toast, ToastContainer } from 'react-toastify'
import { DeleteOutline, DoneAll, Remove, PhotoOutlined, RemoveRedEye } from '@mui/icons-material'
import Loader from '../../components/loader/Loader'
import Toptag from '../../components/topTag/Toptag'
import APIFunction from "../../ApiFunction"
import { BASE_URL } from '../../Api'
import APIFunctions from "../../ApiFunction";
import axios from 'axios'



const NavPaper = styled(Paper)({
  padding: "10px",
  borderRadius: "0",
  backgroundColor: "white",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const BackPaper = styled(Paper)({
  height: "80vh",
  overflow: "auto",
  // padding: "20px",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
});
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const Question = () => {
  const [all, setAll] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [open, setOpen] = useState({ create: "", edit: "" });
  const [obj, setObj] = useState({ pic: "", que: ""})
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(false);
  const [image, setImage] = useState("");
  const [image2, setImage2] = useState("");
  const [putObjurl, setPutObjurl] = useState({ url: '', key: '' });
  const [options, setOptions] = useState([{}]);
  const [selectedAnswer, setSelectedAnswer] = useState(0);
  const [del, setDel] = React.useState(false);
  const [id, setId] = useState()


  const handleCreate = (e) => {
    setError(false)
    setObj({
      ...obj,
      [e.target.name]: e.target.value,
    })
  }
  const getPutObjUrl = async (contentType) => {
    const result = await APIFunctions.putObjectUrl(contentType);
    console.log(result.data.result, "result")
    setPutObjurl(result.data.result)
  }
  const cancelOption = () => {
    setOpen({})
    setSelectedAnswer(0);
    setOptions([{}])
    setError(false)
    setObj({ pic: "", que: ""});
    setImage2('');
    setImage('');
    setOptions([{}])
  }


  const createOneOption = async () => {
    try {
      if (image == "") { alert("Please select vehicle image."); return; }
      console.log(options, "option")
      if (!obj.que || options.some( (option) => !option.option || option.option.trim().length === 0 ) ||
        options.some((option) => Object.keys(option).length === 0) 
      ) {
        alert("All fields are required. Please fill them.");
        return;
      } else {
      setDisable(true)
      if (image2 !== "") {
        const presignedURL = putObjurl.url;
        await axios.put(presignedURL, image2, {
          headers: {
            'Content-Type': image2.type // Set the content type based on the file's type
          }
        });
        obj.pic = putObjurl.key;
      }
      let finalObj = {
        pic: obj.pic,
        que: obj.que,
        options: options,
        answer: selectedAnswer
      }

      const result = await APIFunction.addtriviaquestions(finalObj);
      if (result.data.code === 200) {
        setOpen({});
        setObj({ pic: "", que: ""});
    setImage2('');
    setImage('');

    setOptions([{}])
        getAll();
        setIsLoading(false)
        setObj({ pic: "", que: "" })
        setError(false)
        toast.success("Created Successfully!")
        setDisable(false)
      } else if (result.data.code === 201) {
        toast.error("Please try again!")
        setDisable(false)
      } else if (result.data.code === 203) {
        toast.info("Session Expired. Please login again!!");
        localStorage.clear();
        navigate("/")
        window.location.reload();
      }
      }
    } catch (error) {
      console.log(error.message)
    }
  }


  const handleDeleteOpen = (id, status) => {
    setId(id)
    setDel(true)

  };


  const handleDel = () => {
    setDel(false);
  };

  const imageChange = (e) => {
    console.log('one')
    console.log(e.target.files[0].type, 'hiting');
    getPutObjUrl(e.target.files[0].type); // getting the url here
    setImage2(e.target.files[0]);
    setImage(URL.createObjectURL(e.target.files[0]));
  };

  const deleteTrivQue = async (id) => {
    const result = await APIFunction.deleteTriviaQue(id);
    if (result.data.code === 200) {
      setDel(false);
      getAll();
      toast.success("Successfully deleted!");
    } else if (result.data.code === 201) {
      toast.error("Please try again!");
    } else if (result.data.code === 203) {
      localStorage.clear('admintoken');
      sessionStorage.clear('admintoken');
      navigate("/");
      toast.info("Session Expired. Please log in again!");
    }
  }

  const inputEvent = (e) => {
    setObj({
      ...obj,
      [e.target.name]: e.target.value
    });
  };

  const getAll = async () => {
    try {
      let result = await APIFunctions.getAllTriviaQue();
      if (result.data.code === 200) {
        setAll(result.data.result)
        setIsLoading(false)
      } else if (result.data.code === 203) {
        toast.info("Session Expired. Please login again!!");
        localStorage.clear();
        navigate("/")
        window.location.reload();

      }
    } catch (err) {
      console.log(err.message)
    }
  }

  const addOption = () => {
    setOptions((prevOptions) => [...prevOptions, {}]); // Add a new empty option field
  };

  const removeOption = (index) => {
    const newOptions = [...options];
    newOptions.splice(index, 1); // Remove the option at the specified index
    setOptions(newOptions);
  };

  const handleOptionChange = (index, newValue) => {
    setOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      newOptions[index] = { option: newValue, value: false };
      return newOptions;
    });
  };

  const handleAnswerChange = (event) => {
    setSelectedAnswer(parseInt(event.target.value, 10));
  };


  useEffect(() => {
    getAll();

  }, [])


  return (
    <>


      {
        isLoading ? (
          <Loader />) : (

          <>
            <Toptag />
            <NavPaper>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div></div>
                <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
                  <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Total Trivia Questions:- {all.length}</div></Grid>
                  <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Add New Question:</div></Grid>
                  <Grid item>
                    <button className='newBtn' onClick={() => setOpen({ create: "true" })}>Create</button>
                  </Grid>
                </Grid>

              </div>
            </NavPaper>
            <BackPaper>
              {all.length === 0 ?
                <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                  <Grid item><img src="/images/no-data.png" alt="error-image" style={{ width: "100%", height: '440px' }} /></Grid>
                  <Grid item><div className="errorMessage">No questions added! Click on Create button to add one.</div></Grid>
                </Grid> :
                <>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow className='tableHead'>
                        <TableCell>S.No</TableCell>
                        <TableCell>Question</TableCell>
                        {/* <TableCell>Answer</TableCell> */}
                        <TableCell align="center">Added On</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {all.map((element, index) => {
                        let date = new Date(element.created_at);
                        let datearr = ["Jan", "Feb", "March", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec",];
                        let newDate = date.toISOString().substring(0, 10).split("-")[2] + " " +
                          datearr[parseInt(date.toISOString().substring(0, 10).split("-")[1]) - 1] + " " +
                          date.toISOString().substring(0, 10).split("-")[0];
                        return (
                          <TableRow className='tableData' key={index + 1}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{element.que}</TableCell>
                            { }
                            {/* <TableCell>{element.ans = element.ans == 1 ? element.option1 : element.ans == 2 ? element.option2 : element.ans == 3 ? element.option3 : element.ans == 4 ? element.option4 : 'Invalid Answer'}</TableCell> */}
                            <TableCell align="center">{newDate}</TableCell>
                            {/* <TableCell align="center">
                            <Tooltip title="view message detail" >
                              <NavLink to='/contactus/details'><RemoveRedEye onClick={()=>localStorage.setItem("contact_id",element._id)}className='visibilityIc' /></NavLink>
                            </Tooltip>
                          </TableCell> */}

                            <TableCell align="right">
                              <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" minWidth='120px'>
                                <Grid item>
                                  <Tooltip title="view message detail" placement="top" >
                                    <NavLink to='/trivia-questions/details'><RemoveRedEye onClick={() => localStorage.setItem("que_id", element._id)} className='visibilityIc' /></NavLink>
                                  </Tooltip>
                                </Grid>

                                <Grid item>
                                  <Tooltip title={`Delete`}  placement="top">
                                    <DeleteOutline className="userListDelete" onClick={() => { handleDeleteOpen(element._id) }} />
                                  </Tooltip>
                                </Grid>
                                <Grid item></Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>

                </>}


              {/* dilogue for delete trivia que */}
              <Dialog open={del} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                  {"Are you sure want to delete this Trivia Question?"}
                </DialogTitle>
                <DialogActions>
                  <Button variant='outlined' onClick={handleDel}>No</Button>
                  <Button variant='outlined' onClick={() => { deleteTrivQue(id) }} >Yes</Button>
                </DialogActions>
              </Dialog>





            </BackPaper>
            {/* dilogue for create option */}

            <Dialog open={open.create === "true"}>
              <DialogTitle style={{ width: '600px' }}>Add new trivia question</DialogTitle>
              <DialogContent>
                <Grid  >
                  <Grid xs={12} sm={12} md={6} lg={3} item>
                    <Grid container spacing={2} direction="row" justifyContent="space-evenly" alignItems="center" >
                      <Grid item>
                        <img src={image == "" ? '/images/vehicle_blank.png' : image} style={{ width: '200px', height: '150px' }} alt="selected_image" className="imgCont1" />
                      </Grid>
                      <Grid item>

                        <Grid container spacing={1} direction="column" justifyContent="flex-start" alignItems="center">
                          <Grid item>
                            <label htmlFor='imagechange'>
                              <div className='purchaseChip' style={{ background: "none", width: "225px", textAlign: "center", fontSize: "13px", color: "#465290" }}>
                                <div><PhotoOutlined /></div>
                                <div>Add Vehicle Image</div>
                              </div>
                            </label>
                            <input type='file' id="imagechange" onChange={(e) => imageChange(e)} style={{ display: 'none' }} accept="image/*" />
                          </Grid>

                        </Grid>

                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid xs={12} sm={12} md={6} lg={8} item>
                    <Grid container spacing={1} style={{padding: '0px'}} direction="column" justifyContent="flex-start" alignItems="flex-start">

                      <Grid item>Question</Grid>
                      <Grid item style={{ width: "100%", paddingLeft: "0px" }}>
                        <textarea type="text" value={obj.que} required={true} name="que" id="que" onChange={inputEvent} className="detailBarInput" />
                        {/* <input type="text" disabled={!edit}  value={obj.que} name="que" id="que" onChange={inputEvent} className="detailBarInput" /> */}
                      </Grid>

                      {/* <Grid item>Option1</Grid>
                      <Grid item style={{ width: "100%" }}>
                        <input type="text" value={obj.option1} required={true} name="option1" id="option1" onChange={inputEvent} className="detailBarInput" />
                      </Grid>
                      <Grid item>option2</Grid>
                      <Grid item style={{ width: "100%" }}>
                        <input type="text" value={obj.option2} required={true} name="option2" id="option2" onChange={inputEvent} className="detailBarInput" />
                      </Grid>
                      <Grid item>option3</Grid>
                      <Grid item style={{ width: "100%" }}>
                        <input type="text" value={obj.option3} required={true} name="option3" id="option3" onChange={inputEvent} className="detailBarInput" />
                      </Grid>
                      <Grid item>option4</Grid>
                      <Grid item style={{ width: "100%" }}>
                        <input type="text" value={obj.option4} required={true} name="option4" id="option4" onChange={inputEvent} className="detailBarInput" />
                      </Grid> */}

                      {options.map((option, index) => (
                        <React.Fragment key={index} style={{padding: "0px"}}>
                          <Grid item>{`Option ${index + 1}`}</Grid>
                          <Grid container spacing={1} direction="row" alignItems="center">
                            <Grid item style={{ width: '100%', paddingLeft: "8px" }}>
                              <input
                                type="text"
                                value={option.option}
                                required={true}
                                onChange={(e) => handleOptionChange(index, e.target.value)}
                                className="detailBarInput"
                              />
                            </Grid>
                            {index > 0 && ( // Show remove button for all options except the first one
                              <Grid item style={{ width: '100%', paddingLeft: "8px" }}>
                                <Button onClick={() => removeOption(index)}>
                                  <Remove />
                                </Button>
                              </Grid>
                            )}
                          </Grid>
                        </React.Fragment>
                      ))}
                      <Grid item style={{ width: '100%', paddingLeft: "0px", }}>
                        <Button style={{fontSize: '17px'}} onClick={addOption}>+</Button>
                      </Grid>
                      <Grid item>Answer</Grid>
                      <Grid item style={{ width: '100%' , paddingLeft: "0px"}}>
                        <select
                          value={selectedAnswer}
                          onChange={handleAnswerChange}
                          className="detailBarInput"
                        >
                          {options.map((_, index) => (
                            <option key={index} value={index}>
                              {`Option ${index + 1}`}
                            </option>
                          ))}
                        </select>
                      </Grid>
                    </Grid>
                  </Grid>
                  </Grid>




              </DialogContent>
              <DialogActions>
                <Button onClick={cancelOption} >Cancel</Button>
                <Button onClick={createOneOption} disabled={disable}>Create</Button>
              </DialogActions>
            </Dialog>

          </>

        )
      }

    </>
  )
}

export default Question









