

import { AssignmentLateOutlined, Block, Call, Cancel, DeleteOutline, DoneAll, FileCopyOutlined, FreeBreakfastSharp, GppMaybeOutlined, MarkEmailRead, MarkEmailUnread, PhoneDisabled, PhoneLocked, RemoveCircleOutline, RemoveRedEye, Search, Unsubscribe, VerifiedOutlined, } from '@mui/icons-material'
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/loader/Loader'
import Toptag from '../../components/topTag/Toptag'
import "./user.css"
import APIFunction from "../../ApiFunction"
import { elements } from 'chart.js'
import { fetchUsersCount } from '../../action/count'
import { useDispatch, useSelector } from 'react-redux'
import { BASE_URL, Img_url } from '../../Api'

const NavPaper = styled(Paper)({
  padding: "10px",
  borderRadius: "0",
  backgroundColor: "white",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const BackPaper = styled(Paper)({
  height: "70vh",
  overflow: "auto",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const Technician = () => {
  const dispatch = useDispatch();
  const [usersCount, setUserCount] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [all, setAll] = useState([]);
  const navigate = useNavigate();
  const [row, setRow] = useState(10);
  const [skip, setSkip] = useState(0)
  const [key, setKey] = useState("")
  const [open, setOpen] = React.useState(false);
  const [remopen, setRemOpen] = React.useState(false);
  const [contact, setContact] = React.useState(false);
  const [id, setId] = useState()
  const [status, setStatus] = useState()
  const [disable, setDisable] = useState(false);



  useEffect(() => {
    dispatch(fetchUsersCount());
  }, [dispatch])


  const handleBlockOpen = (id, status) => {
    setId(id)
    setStatus(status)
    setOpen(true);
  };

  const handleBlock = () => {
    setOpen(false);
  };

  const handleEmailOpen = (id) => {
    setId(id)
    setRemOpen(true);
  };

  const handleEmail = () => {
    setRemOpen(false);
  };

  const handleContactOpen = (id) => {
    setId(id)
    setContact(true);
  };

  const handleContact = () => {
    setContact(false);
  };

  const searchHandle = (event) => {
    const newQuery = event.target.value.trim();
    setKey(newQuery)
    setSkip(0);
  }








  const getAll = async () => {
    const result = await APIFunction.getAllTechnicians(key, row, skip);
    if (result.data.code === 200) {
      setAll(result.data.result)
      setUserCount(result.data.total_count)
      setIsLoading(false)
    } else if (result.data.code === 203) {
       toast.info("Session Expired. Please login again!!");
      localStorage.clear();
      navigate("/")
      window.location.reload();
    }
   
  }
  useEffect(() => {
    getAll()
  }, [key, row, skip])


  const updateEmail = async (id) => {
    setDisable(true)
    const result = await APIFunction.updateEmailStatus(id);
    if (result.data.code === 200) {
      setRemOpen(false);
      getAll();
      setDisable(false)
      toast.success("Verify Successfully!")
    } else if (result.data.code === 201) {
      toast.error("Please try again!")
    } else if (result.data.code === 203) {
      window.location.reload()
      localStorage.clear();
      navigate("/")
      toast.info("Session Expired Please login again!!")
    }
  }


  const updateContact = async (id) => {
    setDisable(true)
    const result = await APIFunction.updateContactStatus(id);
    if (result.data.code === 200) {
      setContact(false);
      getAll();
      setDisable(false)
      toast.success("Verify Successfully!")
    } else if (result.data.code === 201) {
      toast.error("Please try again!")
    } else if (result.data.code === 203) {
      window.location.reload()
      localStorage.clear();
      navigate("/")
      toast.info("Session Expired Please login again!!")
    }
  }

  


  const BlockUser = async (id) => {
    let data = {
      blockedbyAdmin: status
    }
    setDisable(true)
    const result = await APIFunction.updateBlockStatus(id, data);
    if (result.data.code === 200) {
      setOpen(false);
      getAll();
      setDisable(false)
      toast.success("Successfully updated!");
    } else if (result.data.code === 201) {
      toast.error("Please try again!")
    } else if (result.data.code === 203) {
      window.location.reload()
      localStorage.clear();
      navigate("/")
      toast.info("Session Expired Please login again!!")
    }
  }


  const handleChange = (e) => {
    if (e.target.value === usersCount) {
      setSkip(0)
      setRow(usersCount)
    } else {
      setRow(e.target.value)
    }
  };
  const skipPagination = (e, page) => {
    setSkip((page - 1) * row)
  }


  return (
    <>
      {
        isLoading ? (
          <Loader />) : (
          <>
            <Toptag />
           
            <NavPaper>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div></div>
                <Grid container spacing={4} direction="row" justifyContent="flex-end" alignItems="center">
                  <Grid item>
                    <div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Active Users {usersCount}</div></Grid>
                  {all.length>0?
                  <>
                  <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Row Per Page </div></Grid>
                  <Grid item>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard-label"
                        value={row}
                        onChange={handleChange}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={usersCount}>All</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  </>:null}
                  <Grid item>
                    <div className='searchBar'>
                      <input type="text" placeholder='Search... ' onChange={searchHandle} id='searchtext' className='searchBarInput' />
                      <Search className='searchIcon' />
                    </div>
                  </Grid>
                </Grid>

              </div>
            </NavPaper>
            <BackPaper>
              {all.length === 0 && key ?
                <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                                 <Grid item><img src="/images/no-data.png" alt="error-image" style={{ width: "50%", heigth: '500px' }} /></Grid>
                  <Grid item><div className="errorMessage">No Data Found!</div></Grid>
                </Grid> :
                <>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>S.No</TableCell>
                        <TableCell>User</TableCell>
                        <TableCell >Name</TableCell>
                        <TableCell >Email</TableCell>
                        <TableCell >Login type</TableCell>
                        <TableCell>Joined Since</TableCell>
                        <TableCell>Email Verified</TableCell>
                        <TableCell>Contact Verified</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {all.map((element, index) => {
                        let date = new Date(element.created_at);
                        let datearr = ["Jan", "Feb", "March", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec",];
                        let newDate = date.toISOString().substring(0, 10).split("-")[2] + " " +
                          datearr[parseInt(date.toISOString().substring(0, 10).split("-")[1]) - 1] + " " +
                          date.toISOString().substring(0, 10).split("-")[0];
                        return (<>
                          <TableRow key={index + 1}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                             {
                            <img src={element.profile_pic && element.profile_pic !== ""  ? `${element.profile_pic}` : '/images/blank_pic.png'} alt="user" style={{ width: "40px", height: "40px", borderRadius: '50px' }} />
                             }
                            </TableCell>
                            <TableCell>{element.user_name}</TableCell>
                            <TableCell>{element.email}</TableCell>
                            <TableCell>
                              {element.login_type === "EMAIL" ? (
                                <img className='logintype' src="/images/email.png" alt="Email" />
                              ) : element.login_type === "GOOGLE" ? (
                                <img className='logintype' src="/images/google.png" alt="Google" />
                              ) : (
                                <img className='logintype' src="/images/phone.png" alt="Phone" />
                              )}
                            </TableCell>
                            <TableCell>{newDate}</TableCell>
                            <TableCell>
                              {element.emailVerified === true ?
                                <div className='purchaseChip'>
                                  <VerifiedOutlined style={{ color: "green" }} />
                                  <div>Verified</div>
                                </div>
                                :
                                <div className='purchaseChip'>
                                  <GppMaybeOutlined style={{ color: "red" }} />
                                  <div>Not Verified</div>
                                </div>
                              }
                            </TableCell>
                            <TableCell>
                              {element.contactVerified === true  ?
                                <div className='purchaseChip'>
                                  <VerifiedOutlined style={{ color: "green" }} />
                                  <div>Verified</div>
                                </div>
                                :
                                <div className='purchaseChip'>
                                  <GppMaybeOutlined style={{ color: "red" }} />
                                  <div>Not Verified</div>
                                </div>
                              }
                            </TableCell>
                          
                            <TableCell align='center'>
                              <Grid container  spacing={1} direction="row" justifyContent="center" alignItems="center">
                                <Grid item>
                                  <Tooltip title="view profile" >
                                    <NavLink to='/technician/details'><RemoveRedEye onClick={()=>localStorage.setItem('user_id',element._id)} className='visibilityIc' /></NavLink>
                                  </Tooltip>
                                </Grid>
                                <Grid item>
                                  {element.blockedbyAdmin === true ?
                                    <Tooltip title={`unblock ${element.user_name}`}>
                                      <Block style={{ color: "red" }} className="blockIcon" onClick={() => { handleBlockOpen(element._id, element.blockedbyAdmin) }} />
                                    </Tooltip>
                                    :
                                    <Tooltip title={`block ${element.user_name}`}>
                                      <Block style={{ color: "grey" }} className="blockIcon" onClick={() => { handleBlockOpen(element._id, element.blockedbyAdmin) }} />
                                    </Tooltip>
                                  }
                                </Grid>
                                <Grid item>
                                  {element.emailVerified === true  ?
                                    <Tooltip title="Email already verified" >
                                      <MarkEmailRead className="userListDelete" style={{ color: "green" }} />
                                    </Tooltip>
                                    :
                                    <Tooltip title={element.email === ""?"User hasn't added any email yet":"Verify Now"}>
                                      {element.email === ""?
                                      <Unsubscribe className="userListDelete"  />
                                      :
                                      <MarkEmailUnread className="userListDelete" onClick={() => { handleEmailOpen(element._id) }} />
                                    }
                                    </Tooltip>
                                  }
                                </Grid>
                                {/* <Grid item>
                                  {element.contactVerified === true  ?
                                    <Tooltip title="Contact already verified" >
                                      <Call className="userListDelete" style={{ color: "green" }} />
                                    </Tooltip>
                                    :
                                    <Tooltip title={element.contact === ""?"User hasn't added any contact yet":"Verify Now"}>
                                      {element.contact === ""?
                                      <PhoneDisabled className="userListDelete"/>
                                      :
                                      <PhoneLocked className="userListDelete" onClick={() => { handleContactOpen(element._id) }} />
                                       }
                                      </Tooltip>
                                  }
                                </Grid> */}
                                <Grid item></Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        </>
                        )

                      })}
                    </TableBody>
                  </Table>

                </>
              }
              {/* dilogue for block user */}
              <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                  {status === true ? ("Are you sure want to unblock this user?") : ("Are you sure want to block this user?")}
                </DialogTitle>
                <DialogActions>
                  <Button variant="outlined" onClick={handleBlock}>Cancel</Button>
                  <Button variant="outlined" onClick={() => { BlockUser(id) }} disabled={disable}>{status === true ? 'unBlock' : 'Block'}</Button>
                </DialogActions>
              </Dialog>

              {/* dilogue for verify user */}
              <Dialog open={remopen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                  {"Are you sure want to verify this user by your side?"}
                </DialogTitle>
                <DialogActions>
                  <Button variant="outlined" onClick={handleEmail}>No</Button>
                  <Button variant="outlined" onClick={() => { updateEmail(id) }} disabled={disable}>Yes</Button>
                </DialogActions>
              </Dialog>

              {/* dilogue for verify user */}
              <Dialog open={contact} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                  {"Are you sure want to verify the contact?"}
                </DialogTitle>
                <DialogActions>
                  <Button variant="outlined" onClick={handleContact}>No</Button>
                  <Button variant="outlined" onClick={() => { updateContact(id) }} disabled={disable}>Yes</Button>
                </DialogActions>
              </Dialog>


            </BackPaper>
            {all.length>0?<div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: 'center', marginTop: "20px", marginBottom: "20px" }}>
              <div>
                <Pagination count={Math.ceil(usersCount / row)} page={Math.floor(skip / row) + 1} variant="outlined" onChange={skipPagination} shape="rounded" />
              </div>
            </div>:null}
          </>

        )}

    </>
  )
}

export default Technician









