import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./Toolbar";
import Loader from '../../components/loader/Loader'

import "react-quill/dist/quill.snow.css";
import Toptag from "../../components/topTag/Toptag";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Grid, Paper, styled } from "@mui/material";
import APIFunctions from "../../ApiFunction";
import axios from "axios";



const BackPaper = styled(Paper)({
    height: "80vh",
    overflow: "auto",
    padding: "10px",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const NavPaper = styled(Paper)({
    padding: "5px",
    borderRadius: "0",
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const EditServiceGuide = () => {
    const [state, setState] = useState('');
    const [id, setId] = useState('')
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [putObjurl, setPutObjurl] = useState({ url: '', key: '' });
    const [type, setType] = useState(3)
    const [disable, setDisable] = useState(false);
    const [obj, setObj] = useState({ title: "" })
    const [img, setImg] = useState("");


    const serviceId = localStorage.getItem('serviceGuide_id');

    const handleChange = data => {
        setState(data)
    };
    const getPutObjUrl = async (contentType) => {
        const result = await APIFunctions.putObjectUrl(contentType);
        setPutObjurl(result.data.result)
    }
    const imageChange = (e) => {
        getPutObjUrl(e.target.files[0].type); // getting the url here
        setImg(e.target.files[0]);
    };
    const inputEvent = (e) => {
        setObj({
            ...obj,
            [e.target.name]: e.target.value
        });
    };

    const updateContent = async () => {
        try {
            if (!obj.title) { alert("Title is required."); return; }
            if (!state) { alert("Some content needs to be added."); return; }
            let data = {
                title: obj.title,
                content: state,
                id: serviceId
            }
            setDisable(true)
            let result = await APIFunctions.updateServiceGuide(data);
            if (result.data.code === 200) {
                toast.success("Successfully Upated!")
                navigate('/service-guide')
                setDisable(false)
            } else if (result.data.code === 203) {
                toast.info("Session Expired. Please login again!!");
                localStorage.clear();
                navigate("/")
                window.location.reload();
            }
        } catch (err) {
            console.log(err.message)
        }
    }

    // const previewContent = () => {
    //     // Gather content page data to be displayed in the preview
    //     const contentData = {
    //         title: obj.title, // Assuming 'obj.title' contains the title data
    //         content: state, // Assuming 'state' contains the content data
    //         // Other data needed for the preview
    //     };

    //     // Construct the HTML content to display in the preview window
    //     const previewHTML = constructPreviewHTML(contentData);

    //     // Open a new window with the preview content
    //     const newWindow = window.open('', '_blank');
    //     if (newWindow) {
    //         newWindow.document.write(previewHTML);
    //         newWindow.focus();
    //         newWindow.document.close();
    //     } else {
    //         console.error('Popup blocked. Please allow popups for this site.');
    //         // Handle popup blocker scenarios
    //     }
    // };

    // // Function to construct the HTML for the preview
    // const constructPreviewHTML = (contentData) => {
    //     const { title, content } = contentData;
      
    //     return `
    //       <!DOCTYPE html>
    //       <html lang="en">
    //       <head>
    //         <meta charset="UTF-8">
    //         <meta name="viewport" content="width=device-width, initial-scale=1.0">
    //         <title>Preview</title>
    //         <!-- Add necessary styles for readability and responsiveness -->
    //         <style>
    //           body {
    //             font-family: Arial, sans-serif;
    //             padding: 10px;
    //             /* Set a maximum width for content and center it */
    //             max-width: 100%;
    //             margin: 0 auto;
    //           }
    //           h1 {
    //             /* Adjust heading styles */
    //             font-size: 1.5rem;
    //             margin-bottom: 10px;
    //           }
    //           div {
    //             /* Style the content area */
    //             word-wrap: break-word; /* Ensure long words wrap */
    //             white-space: pre-wrap; /* Preserve line breaks */
    //             overflow-wrap: break-word; /* Allow overflow wrapping */
    //           }
    //           /* Add more styles for better representation */
    //         </style>
    //       </head>
    //       <body>
    //         <h1>${title}</h1>
    //         <div>${content}</div>
    //       </body>
    //       </html>
    //     `;
    //   };
      


    const getData = async () => {
        let data = await APIFunctions.get_service_guide_detail(serviceId);
        setObj({ title: data.data.result.title })
        setState(data.data.result.content)
        setIsLoading(false)
    }

    useEffect(() => {
        getData()
    }, [])




    return (
        <>
            {
                isLoading ? (
                    <Loader />) : (

                    <>
                        <div className='container' >
                            <Toptag />

                            <NavPaper>
                                {/* <div style={{ display: "flex", justifyContent: "flex-end", }}>
                                    <Grid item>
                                        <button className="newBtn" onClick={updateContent} >UPDATE</button>
                                    </Grid>
                                </div> */}

                                <div style={{ display: "flex", justifyContent: "flex-end", }}>
                                    {/* <Grid item>
                                        <button className="newBtn" onClick={previewContent} >PREVIEW</button>
                                    </Grid> */}

                                    <Grid item>
                                        <button className="newBtn" onClick={updateContent} >UPDATE</button>
                                    </Grid>

                                </div>
                            </NavPaper>

                            <BackPaper>

                                <div style={{ display: "flex", justifyContent: "space-between", }}>

                                    <Grid container spacing={2} direction="column" justifyContent="flex-start" >
                                        {/* <Grid item>
                            <div style={{ fontSize: "17px", fontWeight: "500", color: "black" }}> Banner Image </div>
                            <input type="file" onChange={(e) => imageChange(e)} id="imagechange" />
                        </Grid> */}

                                        <Grid item>
                                            <div style={{ fontSize: "17px", fontWeight: "500", color: "black" }}> Title</div>
                                            <input type="text" name="title" id="title" value={obj.title} onChange={inputEvent} style={{ width: '200px' }} className="detailBarInput" />
                                        </Grid>



                                        <Grid item>
                                            <div style={{ fontSize: "17px", fontWeight: "500", color: "black" }}> Content</div>
                                            <EditorToolbar />
                                            <ReactQuill
                                                style={{ height: '80vh', overflow: "auto" }}
                                                theme="snow"
                                                id='quillState'
                                                value={state}
                                                onChange={handleChange}
                                                placeholder={"It is recommended to add small size of pictures in the content..."}
                                                modules={modules}
                                                formats={formats}
                                            />

                                        </Grid>
                                    </Grid>

                                </div>



                            </BackPaper>
                        </div>
                    </>
                )
            }
        </>

    )
}

export default EditServiceGuide