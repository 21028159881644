import './card.css';
import { ArrowUpward, DirectionsCar, Group, MovieFilter, TipsAndUpdates } from '@mui/icons-material';
import { NavLink, useNavigate } from 'react-router-dom';
import Toptag from '../../components/topTag/Toptag';
import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import APIFunction from '../../ApiFunction'
import Loader from '../loader/Loader';
import { fetchUsersCount } from '../../action/count';
import { useDispatch, useSelector } from 'react-redux';



export default function Card() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const usersCount = useSelector(state => state.count.usersCount);
    const how_to_guide = useSelector(state => state.count.how_to_guide);
    const tips = useSelector(state => state.count.tips);
    const vehicles = useSelector(state => state.count.vehicles);
    // const Tags = useSelector(state => state.count.tags);
    // const contactCount = useSelector(state => state.count.contactCount);


    const isLoading = useSelector(state => state.count.isLoading);
    const error = useSelector(state => state.count.error);


    if(error===203){
        localStorage.clear();
                sessionStorage.clear();
                navigate("/")
                toast.info("Session Expired Please login again!!")
                window.location.reload(true);
    }
    
    useEffect(() => {
      dispatch(fetchUsersCount());
    }, [dispatch]);




    return (<>
         {isLoading?<Loader />:<>
         <Toptag />
    <div className="container-fluid">
         
         <div className="cardWrapper">
             <NavLink to='/users' className='link'>
                 <div className="cardContainer" >
                     <div className="cardTopLogo">
                         <Group className='cardLogo' sx={{color: '#a07c7c'}}/>
                     </div>
                     <span className='cardTitle'>Users</span>
                     <span className='userCount'>{usersCount}</span>
                 </div>
             </NavLink>
             <NavLink to='/videos' className='link'>
                 <div className="cardContainer" >
                     <div className="cardTopLogo">
                         <MovieFilter className='cardLogo' sx={{color: '#6868a4'}} />
                     </div>
                     <span className='cardTitle'>How to guide</span>
                     <span className='userCount'>{how_to_guide}</span>
                 </div>
             </NavLink>
             <NavLink to='/tips' className='link'>
                 <div className="cardContainer" >
                     <div className="cardTopLogo">
                         <TipsAndUpdates className='cardLogo' sx={{color:'#ffffb1'}} />
                     </div>
                     <span className='cardTitle'>Tips for you</span>
                     <span className='userCount'>{tips}</span>
                 </div>
             </NavLink>
             <NavLink to='/vehicles' className='link'>
                 <div className="cardContainer" >
                     <div className="cardTopLogo">
                         <DirectionsCar className='cardLogo' sx={{color:'#35bbc9'}} />
                     </div>
                     <span className='cardTitle'>Vehicles</span>
                     <span className='userCount'>{vehicles}</span>
                 </div>
             </NavLink>
             
         </div>
     </div></>
         }
            

     
    
    </>	
   

    )
}

