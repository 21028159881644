import React, { useEffect, useState } from 'react'
import { DoneAll, RemoveRedEye } from '@mui/icons-material'
import { Grid, Paper, styled, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import Toptag from '../../components/topTag/Toptag'
import { toast, ToastContainer } from 'react-toastify'
import { NavLink, useNavigate } from 'react-router-dom'
import Loader from '../../components/loader/Loader'
import APIFunctions from '../../ApiFunction'


const BackPaper = styled(Paper)({
  height: "80vh",
  overflow: "auto",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const Contact = () => {
  const [all, setAll] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [id, setId] = useState()

  const getAll = async () => {
    try {
      let result = await APIFunctions.getAllContactMessages()
      if (result.data.code === 200) {
        setAll(result.data.result)
        setIsLoading(false)
      } else if (result.data.code === 203) {
        toast.info("Session Expired. Please login again!!");
        localStorage.clear();
        navigate("/")
        window.location.reload();
      

      }
    } catch (err) {
      console.log(err.message)
    }
  }
  
  useEffect(() => {
    getAll()
  }, [])


  return (
    <>
      {isLoading ? <Loader /> :
        <>
          <Toptag />
          <ToastContainer  autoClose={1000}/>
          <BackPaper>
            {all.length === 0 ?
              <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                <Grid item><img src="/images/no-data.png" alt="error-image" style={{  width: "100%" }} /></Grid>
                <Grid item><div className="errorMessage">Don't have any messages!</div></Grid>
              </Grid> :
              <>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow className='tableHead'>
                      <TableCell>S.No</TableCell>
                      <TableCell>User</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Query</TableCell>
                      <TableCell align="center">Message On</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {all.map((element, index) => {
                      let date = new Date(element.created_at);
                      let datearr = ["Jan", "Feb", "March", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec",];
                      let newDate = date.toISOString().substring(0, 10).split("-")[2] + " " +
                        datearr[parseInt(date.toISOString().substring(0, 10).split("-")[1]) - 1] + " " +
                        date.toISOString().substring(0, 10).split("-")[0];
                      return (
                        <TableRow className='tableData' key={index + 1}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                             {
                            <img src={element.userdet.profile_pic && element.userdet.profile_pic !== ""  ? `${element.userdet.profile_pic}` : '/images/blank_pic.png'} alt="user" style={{ width: "40px", height: "40px", borderRadius: '50px' }} />
                             }
                            </TableCell>
                            <TableCell>{element.userdet.user_name}</TableCell>
                          <TableCell>{element.title}</TableCell>
                          <TableCell align="center">{newDate}</TableCell>
                          <TableCell align="center">
                            <Tooltip title="view message detail" >
                              <NavLink to='/contactus/details'><RemoveRedEye onClick={()=>localStorage.setItem("contact_id",element._id)}className='visibilityIc' /></NavLink>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>

              </>}

          </BackPaper>
        </>
      }

    </>
  )
}

export default Contact