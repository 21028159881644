
import { AddToPhotos, Block, DeleteOutline, DeleteOutlined, FreeBreakfastSharp, GppMaybeOutlined, Person, PhotoOutlined, VerifiedOutlined, WorkspacePremium } from '@mui/icons-material'
import { Chip, Grid, Paper, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import APIFunctions from '../../ApiFunction'
import Toptag from '../../components/topTag/Toptag';
import Loader from '../../components/loader/Loader'
import { BASE_URL, Img_url } from '../../Api'

const BackPaper = styled(Paper)({
  height: "80vh",
  borderRadius: "0",
  padding: "20px",
  overflow: "auto",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const VehicleDetail = () => {
  const id = localStorage.getItem('vehicle_id');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [edit, setEdit] = useState(false)
  const [disable, setDisable] = useState(false)
  const [obj, setObj] = useState(
    { year: "", make: "", model: "", pic: "" }
  )

  const inputEvent = (e) => {
    setObj({
      ...obj,
      [e.target.name]: e.target.value
    });
  };


  const getAll = async () => {
    const result = await APIFunctions.GET_VEHICLE_DETAILS(id);
    if (result.data.code === 200) {
      setObj(result.data.result)
      setIsLoading(false)
    } else if (result.data.code === 201) {
      toast.error("error are coming for fetching images")
    } else if (result.data.code === 203) {
      window.location.reload()
      localStorage.clear();
      navigate("/")
      toast.info("Session Expired Please login again!!")
    }
  }
  const updateUser = async () => {
    let data = {
      id: id,
      user_name: obj.user_name,
      email: obj.email,
      contact: obj.contact
    }
    setDisable(true)
    const result = await APIFunctions.updateUserDetails(data);
    if (result.data.code === 200) {
      setIsLoading(false)
      setEdit(false)
      toast.success("User update successfully!")
      setDisable(true)
    } else if (result.data.code === 201) {
      toast.error(result.data.message)
      setDisable(false)
    } else if (result.data.code === 203) {
      toast.info("Session Expired. Please login again!!");
      localStorage.clear();
      navigate("/")
      window.location.reload();
    }
  }


  useEffect(() => {
    setIsLoading(true)
    getAll()
  }, [])

  return (
    <>
      {isLoading ?
        <Loader />
        :
        <>
          <Toptag />
      
          <BackPaper>

            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item style={{ fontSize: "20px", fontWeight: "400", color: "grey" }}>
                Vehicle Details
              </Grid>
            </Grid>

            <hr></hr>

            <Grid container >
              <Grid xs={12} sm={12} md={6} lg={5} item >

                {/* <Grid container spacing={3} direction="column" justifyContent="flex-start" alignItems="center"> */}
                  <Grid item>
                    {
                      <img src={obj?.pic && obj?.pic !== "" ? `${obj.pic}` : '/images/vehicle_blank.png'}
                        alt=""
                        className='imgCont1' />
                    }
                  </Grid>
                {/* </Grid> */}

              </Grid>

              <Grid xs={12} sm={12} md={6} lg={7} item>
                <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="flex-start">

                  <Grid item>VIN</Grid>
                  <Grid item style={{ width: "100%" }}>
                    <input type="text" disabled={!edit} value={obj.VIN !== "" ? obj.VIN : "NA"} name="VIN" id="VIN" onChange={inputEvent} className="detailBarInput" />
                  </Grid>
                  <Grid item>Year</Grid>
                  <Grid item style={{ width: "100%" }}>
                    <input type="text" disabled={!edit} value={obj.year !== ""? obj.year : "NA"} name="year" id="year" onChange={inputEvent} className="detailBarInput" />
                  </Grid>

                  <Grid item>Make</Grid>
                  <Grid item style={{ width: "100%" }}>
                    <input type="text" disabled={!edit} value={obj.make !== "" ? obj.make : "NA"} name="make" id="make" onChange={inputEvent} className="detailBarInput" />
                  </Grid>

                  <Grid item>Model</Grid>
                  <Grid item style={{ width: "100%" }}>
                  <input type="text" disabled={!edit} value={obj.model !== "" ? obj.model : "NA"} name="model" id="model" onChange={inputEvent} className="detailBarInput" />
                  </Grid>

                  <Grid item>Manufacturer</Grid>
                  <Grid item style={{ width: "100%" }}>
                  <input type="text" disabled={!edit} value={obj.manufacturer !== "" ? obj.manufacturer : "NA"} name="manufacturer" id="manufacturer" onChange={inputEvent} className="detailBarInput" />
                  </Grid>

                  <Grid item>Engine</Grid>
                  <Grid item style={{ width: "100%" }}>
                  <input type="text" disabled={!edit} value={obj.engine !== "" ? obj.engine : "NA"} name="engine" id="engine" onChange={inputEvent} className="detailBarInput" />
                  </Grid>

                  <Grid item>Transmission</Grid>
                  <Grid item style={{ width: "100%" }}>
                  <input type="text" disabled={!edit} value={obj.transmission !== "" ? obj.transmission : "NA"} name="transmission" id="transmission" onChange={inputEvent} className="detailBarInput" />
                  </Grid>

                  <div style={{color: 'grey', marginLeft: '14px', marginTop: '14px'}}>User Details</div>

                  <Grid item>Email</Grid>
                  <Grid item style={{ width: "100%" }}>
                  <input type="text" disabled={!edit} value={obj.userdet ? obj?.userdet.email : 'NA' } name="email" id="email" onChange={inputEvent} className="detailBarInput" />
                  </Grid>
                  <Grid item>Username</Grid>
                  <Grid item style={{ width: "100%" }}>
                  <input type="text" disabled={!edit} value={obj.userdet ? obj?.userdet.user_name : 'NA' } name="user_name" id="user_name" onChange={inputEvent} className="detailBarInput" />
                  </Grid>

                </Grid>
              </Grid>
            </Grid>

          </BackPaper>
        </>}

    </>
  )
}

export default VehicleDetail