import { createAsyncThunk } from "@reduxjs/toolkit";
import APIFunction from "../ApiFunction";
import { toast } from "react-toastify";


export const fetchUsersCount = createAsyncThunk(
    'count/fetchUsersCount',
    async (_, { rejectWithValue }) => {
    const response = await APIFunction.getCounts();
    if(response.data.code === 200){
      return response.data.obj;
    } else if(response.data.code === 203){
      return rejectWithValue(response.data.code);
    }
  } 
);
