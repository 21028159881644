import { Grid, Paper } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/loader/Loader'
import Toptag from '../../components/topTag/Toptag'
import "../user/userDetail.css"
import APIFunctions from '../../ApiFunction'


const BackPaper =  styled(Paper)({
  height:"80vh",
  borderRadius:"0",
  padding:"20px",
  overflow:"auto",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const ContactDet = () => {
  
  const id = localStorage.getItem('contact_id');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [obj,setObj] = useState({})
  
  

 
  const getAll = async ()=>{
    try{
      let result = await APIFunctions.getSingleContactMessages(id)
      if(result.data.code===200){
        setIsLoading(false)
        setObj(result.data.result)
      }else if(result.data.code===203){
        toast.info("Session Expired. Please login again!!");
        localStorage.clear();
        navigate("/")
        window.location.reload();
      }
    }catch(err){
      console.log(err.message)
    }
    } 

    useEffect(()=>{
      getAll()
    },[])
  return (
    <>
    {isLoading?<Loader />:
    <>
     <Toptag />
  
    <BackPaper>
    <Grid container  direction="row"  justifyContent="space-between" alignItems="center">
      <Grid item style={{fontSize:"15px",fontWeight:"400",color:"grey"}}>Contact Us Message</Grid>
    </Grid>
    <hr></hr>
      <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="flex-start">
      <Grid item>Name</Grid>
      <Grid item style={{ width: "100%" }}>
      <input type="text"  disabled={true} value={obj.userdet?.user_name ?? 'NA'}   name="title" id="title" className="detailBarInput" />
      </Grid>
      <Grid item>Email</Grid>
      <Grid item style={{ width: "100%" }}>
      <input type="text"  disabled={true} value={obj.userdet?.email ?? 'NA'}   name="title" id="title" className="detailBarInput" />
      </Grid>
      <Grid item>Contact</Grid>
      <Grid item style={{ width: "100%" }}>
      <input type="text"  disabled={true} value={obj.contact !== "" ? (obj.userdet?.country_code  + obj.userdet?.contact) : 'NA'}   name="title" id="title" className="detailBarInput" />
      </Grid>
      <Grid item>Reason</Grid>
      <Grid item style={{ width: "100%" }}>
        <textarea type="text" style={{height:"180px"}} disabled={true}    value={obj.title ?? 'NA'}   name="message" id="message" className="detailBarInput" />
      </Grid>  
      <Grid item>Message</Grid>
      <Grid item style={{ width: "100%" }}>
        <textarea type="text" style={{height:"180px"}} disabled={true}    value={obj.description ?? 'NA'}   name="message" id="message" className="detailBarInput" />
      </Grid>  
      </Grid>
    </BackPaper>
    </>
    }
   
    </>
  )
}

export default ContactDet

