import { Add, Block, BlockOutlined, Cancel, DeleteOutline, DoneAll, FreeBreakfastSharp, RemoveCircleOutline, RemoveRedEye, ReportGmailerrorredOutlined, Search, UnarchiveOutlined, WorkspacePremium } from '@mui/icons-material'
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/loader/Loader'
import Toptag from '../../components/topTag/Toptag'
import "./user.css"
import APIFunction from "../../ApiFunction"
import { BASE_URL, Img_url } from '../../Api'


const NavPaper = styled(Paper)({
  padding: "10px",
  borderRadius: "0",
  backgroundColor: "white",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const BackPaper = styled(Paper)({
  height: "70vh",
  overflow: "auto",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const Deleted = () => {
  const [all, setAll] = useState([]);
  const [user, setUser] = useState()
  const navigate = useNavigate();
  const [row, setRow] = useState(10);
  const [skip, setSkip] = useState(0)
  const [key, setKey] = useState("")
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [archived, setArchived] = React.useState(false);
  const [id, setId] = useState()
  const [status, setStatus] = useState()
  const [disable, setDisable] = useState(false);




  const handleDeleteOpen = (id, status) => {
    setId(id)
    setStatus(status)
    setOpen(true);
  };

  const handleDelete = () => {
    setOpen(false);
  };

  const handleAROpen = (id) => {
    setId(id)
    setArchived(true);
  };

  const handleAR = () => {
    setArchived(false);
  };

  const searchHandle = (event) => {
    setKey(event.target.value)
  }

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));







  const getAll = async () => {
    const result = await APIFunction.getAllDeletedUsers(key, row, skip);
    if (result.data.code === 203) {
       toast.info("Session Expired. Please login again!!");
      localStorage.clear();
      navigate("/")
      window.location.reload();
    } else {
      setAll(result.data.result)
      setUser(result.data.total_count)
      await delay(1000);
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getAll()
  }, [key, row, skip])

  const unArchived = async (id) => {
    setDisable(true)
    const result = await APIFunction.ArchivedUserStatus(id);
    console.log(result, "evevevev")
    if (result.data.code === 200) {
      setArchived(false);
      getAll();
      setDisable(false)
      toast.success("Successfully updated!")
    } else if (result.data.code === 201) {
      toast.error("Please try again!")
    } else if (result.data.code === 203) {
      window.location.reload()
      localStorage.clear()
      navigate("/")
      toast.info("Session Expired Please login again!!")
    }
  }



  const deleteUser = async (id) => {
    setDisable(true)
    const result = await APIFunction.deleteUserPermanent(id);
    if (result.data.code === 200) {
      setOpen(false);
      getAll();
      setDisable(false)
      toast.success("Successfully deleted!");
    } else if (result.data.code === 201) {
      toast.error("Please try again!")
    } else if (result.data.code === 203) {
      localStorage.clear('admintoken');
      sessionStorage.clear('admintoken');
      navigate("/")
      toast.info("Session Expired Please login again!!")
    }
  }


  const handleChange = (e) => {
    if (e.target.value === user) {
      setSkip(0)
      setRow(user)
    } else {
      setRow(e.target.value)
    }
  };
  const skipPagination = (e, page) => {
    setSkip((page - 1) * row)
  }
  return (
    <>


      {
        isLoading ? (
          <Loader />) : (
          <>
            <Toptag />
            <ToastContainer autoClose={1000} />
            <NavPaper>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div></div>
                <Grid container spacing={1} direction="row" justifyContent="flex-end" alignItems="center">
                  {all.length > 0 ?
                    <>
                      <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Row Per Page:- </div></Grid>
                      <Grid item>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard-label"
                            value={row}
                            onChange={handleChange}
                          >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                            <MenuItem value={user}>All</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <div className='searchBar'>
                          <input type="text" placeholder='Search... ' onChange={searchHandle} id='searchtext' className='searchBarInput' />
                          <Search className='searchIcon' />
                        </div>
                      </Grid></> : null}
                </Grid>

              </div>
            </NavPaper>
            <BackPaper>
              {all.length === 0 ?
                <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="center" alignItems="center">
                  <Grid item><img src="/images/no-data.png" alt="error-image" style={{ width: "50%", heigth: '500px' }} /></Grid>
                  <Grid item><div className="errorMessage">No Data Found!</div></Grid>
                </Grid> :
                <>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>S.No</TableCell>
                        <TableCell>User</TableCell>
                        <TableCell >Name</TableCell>
                        <TableCell >Email</TableCell>
                        <TableCell>Joined Since</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {all.map((element, index) => {
                        let date = new Date(element.created_at);
                        let datearr = ["Jan", "Feb", "March", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec",];
                        let newDate = date.toISOString().substring(0, 10).split("-")[2] + " " +
                          datearr[parseInt(date.toISOString().substring(0, 10).split("-")[1]) - 1] + " " +
                          date.toISOString().substring(0, 10).split("-")[0];
                        return (<>
                          <TableRow key={index + 1}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              {
                                // <img src={`${element.profile_pic && element.profile_pic === "" && element.profile_pic === "blank_pic.png" ? '/images/blank_pic.png' : Img_url + userDetails.Admin_image}`} alt="user" style={{ width: "40px", height: "40px", borderRadius: '50px' }} />
                                <img src={element.profile_pic && element.profile_pic !== "" ? `${element.profile_pic}` : '/images/blank_pic.png'} alt="user" style={{ width: "40px", height: "40px", borderRadius: '50px' }} />
                              }

                            </TableCell>
                            <TableCell>{element.user_name}</TableCell>
                            <TableCell>{element.email}</TableCell>
                            <TableCell>{newDate}</TableCell>
                            <TableCell align="right">
                              <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                                <Grid item>
                                  <Tooltip title="view profile" >
                                    <NavLink to='/deleted-users/details'><RemoveRedEye onClick={() => localStorage.setItem('user_id', element._id)} className='visibilityIc' /></NavLink>
                                  </Tooltip>
                                </Grid>
                                {/* <Grid item>
                                  <Tooltip title={`unarchived ${element.user_name}`} >
                                    <UnarchiveOutlined className="userListDelete" onClick={() => { handleAROpen(element._id) }} />
                                  </Tooltip>
                                </Grid> */}
                                <Grid item>
                                  <Tooltip title={`Delete ${element.user_name}`} >
                                    <DeleteOutline className="userListDelete" onClick={() => { handleDeleteOpen(element._id) }} />
                                  </Tooltip>
                                </Grid>
                                <Grid item></Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        </>
                        )

                      })}
                    </TableBody>
                  </Table>

                </>
              }
              {/* dilogue for block user */}
              <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                  Are you sure want to delete this user?
                </DialogTitle>
                <DialogActions>
                  <Button variant='outlined' onClick={handleDelete}>Cancel</Button>
                  <Button variant='outlined' onClick={() => { deleteUser(id) }} disabled={disable}>Delete</Button>
                </DialogActions>
              </Dialog>

              {/* dilogue for delete user */}
              <Dialog open={archived} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                  {"Are you sure want to unarchived this user?"}
                </DialogTitle>
                <DialogActions>
                  <Button variant='outlined' onClick={handleAR}>No</Button>
                  <Button variant='outlined' onClick={() => { unArchived(id) }} disabled={disable}>Yes</Button>
                </DialogActions>
              </Dialog>

            </BackPaper>
            {all.length > 0 ? <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: 'center', marginTop: "20px", marginBottom: "20px" }}>
              <div>
                <Pagination count={Math.ceil(user / row)} page={Math.floor(skip / row) + 1} variant="outlined" onChange={skipPagination} shape="rounded" />
              </div>
            </div> : null}
          </>

        )}

    </>
  )
}

export default Deleted









