import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Toptag from "../../components/topTag/Toptag";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Grid, Paper, styled } from "@mui/material";
import APIFunctions from "../../ApiFunction";
import Button from '@mui/material/Button';
import axios from "axios";
import { Cancel, CloudDone, CloudUpload, ConnectingAirportsOutlined, WindowSharp } from "@mui/icons-material";
import LinearWithValueLabel from '../../components/progressbar/LinearWithValueLabel';



const BackPaper = styled(Paper)({
    height: "80vh",
    overflow: "auto",
    padding: "10px",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const NavPaper = styled(Paper)({
    padding: "5px",
    borderRadius: "0",
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const AddVideos = () => {
    const [steps, setSteps] = useState([]);
    const [parts, setParts] = useState([]);
    const [putObjurl, setPutObjurl] = useState('');
    const [type, setType] = useState(3)

    const [obj, setObj] = useState({ title: "" })
    /////////////////////////
    const navigate = useNavigate();

    const [fileDuration, setFileDuration] = useState('');
    const [img, setImg] = useState("")
    const [stepsImg, setStepsImg] = useState([]);
    const [stepInputs, setStepInputs] = useState([]);
    const [partInputs, setPartInputs] = useState([]);
    const [filekey, setFilekey] = useState('');
    const [vid, setVid] = useState("")
    const [vidInput, setVidInput] = useState(true);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [completed, setCompleted] = useState(false);
    const [xhrInstance, setXhrInstance] = useState(null);
    const [tags, setTags] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const [selectedVehicle, setSelectedVehicle] = useState(''); // State to store selected vehicle
    const [vehicleList, setVehicleList] = useState([]); // Example vehicle list
  
  
    ////////////////////////////    




    const handleUpload = async ({ file, presignedURL }) => {
        console.log(file, 'hfile here')
        if (file) {
            setCompleted(false);
            setVidInput(false)
            // Create an XMLHttpRequest object
            const xhr = new XMLHttpRequest();
            setXhrInstance(xhr);
            // Track progress
            xhr.upload.addEventListener('progress', (e) => {
                if (e.lengthComputable) {
                    const percentCompleted = Math.round((e.loaded * 100) / e.total);
                    setUploadProgress(percentCompleted);
                }
            });

            // Handle response
            xhr.addEventListener('load', () => {
                if (xhr.status === 200) {
                    setCompleted(true)
                    setUploadProgress(0); // Reset progress after completion
                } else {
                    console.error('Upload error. Unexpected status:', xhr.status);
                    setUploadProgress(0); // Reset progress on error
                }
            });

            // Open and send the request
            xhr.open('PUT', presignedURL, true);
            xhr.send(file);
        }
    };
    const handleCancelUpload = () => {
        if (xhrInstance) {
            xhrInstance.abort(); // Abort the ongoing upload
            setUploadProgress(0);
            setCompleted(false);
            setVidInput(true)
            setVid('')
            let vidFileSelcted = document.getElementById('video');
            vidFileSelcted.value = '';
        }
    };

    const getPutObjUrlImage = async (contentType) => {
        const result = await APIFunctions.putObjectUrl(contentType);
        return result;
    }
    const getPutObjUrlImageSteps = async (contentType) => {
        const result = await APIFunctions.putObjectUrl(contentType);
        return result;
    }
    const getPutObjUrlVideo = async (contentType) => {
        const result = await APIFunctions.putObjectUrl(contentType);
        return result;
    }

    const handleFileChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            let file = e.target.files[0];
            let url = await getPutObjUrlVideo(file.type);
            let video = document.createElement('video');
            video.src = URL.createObjectURL(file);

            video.onloadedmetadata = () => {
                const durationInSeconds = Math.round(video.duration);
                const minutes = Math.floor(durationInSeconds / 60);
                const seconds = durationInSeconds % 60;

                const checkDuration = minutes + seconds / 60; // Duration in minutes including fractions

                if (checkDuration < 1 || checkDuration > 60) {
                    window.alert('Video length must be greater than 1 minute and less than 60 minutes.');
                    URL.revokeObjectURL(video.src);
                    return;
                }

                const formattedDuration = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                setFileDuration(formattedDuration);
                URL.revokeObjectURL(video.src);

                // Rest of your code that depends on the video duration check
                
                if (url.data.code === 200) {
                    console.log("setting the file here", file)
                    setFilekey(url.data.result.key);
                    setVid(file);
                    handleUpload({ file, presignedURL: url.data.result.url });
                } else {
                    window.alert('Oops! Something went wrong.');
                }
            };

            // Clean up the URL object if the onloadedmetadata event doesn't trigger
            video.onerror = () => {
                URL.revokeObjectURL(video.src);
            };
        }
    };

    const imageChange = async (e) => {
        setImg(e.target.files[0]);
        if (e.target.files && e.target.files.length > 0) {
            let url = await getPutObjUrlImage(e.target.files[0].type); // getting the url here
            setPutObjurl(url.data.result.key);
            if (url.data.code == 200) {
                await axios.put(url.data.result.url, e.target.files[0], {
                    headers: {
                        'Content-Type': e.target.files[0].type
                    }
                });
            } else {
                return window.alert("Oops! Something went wrong.");
            }
        }
    };
    const StepimageChange = async (e) => {
        console.log('image change hit')
        if (e.target.files && e.target.files.length > 0) {
            let url = await getPutObjUrlImageSteps(e.target.files[0].type);
            if (url.data.code == 200) {
                await axios.put(url.data.result.url, e.target.files[0], {
                    headers: {
                        'Content-Type': e.target.files[0].type
                    }
                });
                setStepsImg([
                    ...stepsImg,
                    {
                        key: url.data.result.key,
                    }
                ]);
            } else {
                return window.alert("Oops! Something went wrong.");
            }
        }

    };

    const handlePartInputChange = (e, index) => {
        const { value } = e.target;
        const updatedPartInputs = [...partInputs];

        updatedPartInputs[index] = {
            ...updatedPartInputs[index],
            [index]: value,
        };
        setPartInputs(updatedPartInputs);
    };

    const handleKeyDown = e => {
        if (e.target.value === "" && e.key === " ") {
          e.preventDefault();
        }
      };

    const inputEvent = (e) => {
        setObj({
            ...obj,
            [e.target.name]: e.target.value
        });
    
    };
    const updateContent = async () => {
        try {
            console.log(vid, "file in put")

            if (img === "") { alert("Please select thumbnail"); return; }
            if (vid === "") { alert("Please select video to upload"); return; }
            if (!obj.title) { alert("Title is required."); return; }
            if (obj.title.length < 5) { alert("Title must contain atleast 5 characters"); return; }
            if (!obj.description) { alert("Description is required."); return; }
            if (obj.description.length < 25) { alert("Description must contain atleast 25 characters"); return; }
            // if (selectedVehicle === "") { alert("Please select a vehicle."); return; }
            if (!completed) { alert("Please wait while file is being uploaded."); return; }

            let validationFailed = false;
            const Array_Steps = [];
            const arr = [];

          
     
            stepsImg.forEach((imgItem, index) => {
                const guideTitle = stepInputs[index]?.guide_title || '';
                const imageKey = imgItem.key;
                const startTimeMins = stepInputs[index]?.start_time_minutes || '';
                const startTimeSecs = stepInputs[index]?.start_time_seconds || '';
                if (!imageKey) {
                    validationFailed = true;
                    return window.alert(`No thumbnail selected for Step #${index + 1}`);
                }
                if (guideTitle === '') {
                    validationFailed = true;
                    return window.alert(`Guide Title in Step #${index + 1} can't be empty`);
                }
                if (startTimeMins === '') {
                    validationFailed = true;
                    return window.alert(`startTime(MM) in Step #${index + 1} can't be empty`);
                }
                if (startTimeSecs === '') {
                    validationFailed = true;
                    return window.alert(`startTime(SS) in Step #${index + 1} can't be empty`);
                }

                let finalTime = `${startTimeMins}.${startTimeSecs}`;

                if (arr.length > 0) {
                    let v = arr[index - 1];
                    let res = finalTime - v;
                    let fileDur = fileDuration.split(':');
                    let finalDur = `${fileDur[0] === '00' ? 0 : fileDur[0]}.${fileDur[1]}`;

                    if (v > finalDur) {
                        validationFailed = true;
                        return window.alert(`Start time must be 10 seconds less than the video duration`);
                    }
                    if (res < 0) {
                        validationFailed = true;
                        return window.alert(`Start time at Step #${index + 1} can't be smaller than previous step time.`);
                    }
                    if (res < 0.10) {
                        validationFailed = true;
                        return window.alert(`Start time gap between Step #${index + 1} and Step #${index} must be greater than 10 seconds.`);
                    }

                }
                arr.push(finalTime);

                let startTime = `${startTimeMins}:${startTimeSecs}`;
                Array_Steps.push({
                    image: imgItem.key,
                    guide_title: stepInputs[index].guide_title,
                    start_time: startTime,
                });
                if (index === 0 && startTime !== '00:00') {
                    validationFailed = true;
                    return window.alert(`Start Time in Step #${index + 1} should be 00:00`);
                }

            });
        



            if (!validationFailed) {

                let Array_parts = partInputs.map((item, index) => item[index]);

                /////////////////////////////
                let data = {
                    video: filekey,
                    video_length: fileDuration,
                    tags: tags,
                    thumbnail: putObjurl,
                    title: obj.title,
                    description: obj.description,
                    steps: Array_Steps,
                    parts: Array_parts,
                    // vehicle: selectedVehicle
                }

                let result = await APIFunctions.addVideos(data);

                if (result.data.code === 200) {
                    toast.success("Successfully Added!")
                    navigate('/videos')
                } else if (result.data.code === 203) {
                    toast.info("Session Expired. Please login again!!");
                    localStorage.clear();
                    navigate("/")
                    window.location.reload();
                }
            }
        } catch (err) {
            console.log(err.message)
            window.alert('Something went wrong!');
            navigate('/')
        }
    }

    const addStep = () => {
        if (fileDuration === "") return window.alert('Please add Video first.')
        setSteps((prevOptions) => [...prevOptions, {}]);
    }
    const addPart = () => {
        if (fileDuration === "") return window.alert('Please add Video first.')
        setParts((prevOptions) => [...prevOptions, {}]);
    }
    const removeOption = (index) => {
        const newSteps = [...steps];
        const newStepInputs = [...stepInputs];

        newSteps.splice(index, 1); // Remove the step at the specified index
        newStepInputs.splice(index, 1); // Remove the step input at the specified index

        setSteps(newSteps);
        setStepInputs(newStepInputs);

        const newStepsImg = [...stepsImg];
        newStepsImg.splice(index, 1); // Remove the step image at the specified index
        setStepsImg(newStepsImg);
    };


    const removePart = (index) => {
        const newPart = [...parts];
        const newPartInputs = [...partInputs]
        newPart.splice(index, 1); // Remove the option at the specified index
        newPartInputs.splice(index, 1);
        setParts(newPart);
        setPartInputs(newPartInputs)
    };

    const handleInputChangeTag = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputKeyDownTag = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            const tag = inputValue.trim();
            if (tag) {
                setTags([...tags, tag]);
                setInputValue('');
            }
        } else if (e.key === 'Backspace' && inputValue === '') {
            // Remove the last tag when backspace is pressed and the input is empty
            setTags(tags.slice(0, -1));
        }
    };
    const removeTag = (indexToRemove) => {
        setTags(tags.filter((_, index) => index !== indexToRemove));
    };

    const handleStepInputChange = (e, index) => {
        const { name, value } = e.target;
        const newStepInputs = [...stepInputs];
        if (!newStepInputs[index]) {
            newStepInputs[index] = {
                guide_title: '',
                // start_time: '',
                start_time_minutes: '',
                start_time_seconds: ''
            };
        }

        newStepInputs[index] = {
            ...newStepInputs[index],
            [name]: value,
        };
        setStepInputs(newStepInputs);
    };

    // Function to handle changes in the minutes input
    const handleMinutesInputChange = (e, index) => {
        let value = e.target.value;

        // Allow '00' as valid input and ensure the value is within the range of 0 to 999
        let intVal = parseInt(value);
        if (isNaN(intVal) || intVal < 0 || intVal > 99) {
            value = '';
        }

        // Update the state/context with the new minutes value
        setStepInputs(prevInputs => {
            const updatedInputs = [...prevInputs];
            updatedInputs[index] = {
                ...updatedInputs[index],
                start_time_minutes: value === '' ? '' : value.toString()
            };
            return updatedInputs;
        });
    };

    // Function to handle changes in the seconds input
    const handleSecondsInputChange = (e, index) => {
        let value = e.target.value;

        let intVal = parseInt(value);
        if (isNaN(intVal) || intVal < 0 || intVal > 59) {
            value = '';
        }
        if (value.length === 1) {
            if (value > 5) {
                value = ''
            }
        }

        setStepInputs(prevInputs => {
            const updatedInputs = [...prevInputs];
            updatedInputs[index] = {
                ...updatedInputs[index],
                start_time_seconds: value === '' ? '' : value.toString()
            };
            return updatedInputs;
        });
    };



      


    useEffect(() => {  /// handling browser tab close before completion of video upload
        const handleWindowClose = (event) => {
            if (uploadProgress > 0 && !completed) {
                const confirmationMessage = 'Upload is in progress. Are you sure you want to leave?';
                event.preventDefault();
                event.returnValue = confirmationMessage;
                return confirmationMessage;
            }
        };

        window.addEventListener('beforeunload', handleWindowClose);

        return () => {
            window.removeEventListener('beforeunload', handleWindowClose);
        };
    }, [uploadProgress, completed]);


    



    return (
        <div className='container' >
            <Toptag />

            <NavPaper>
                <div style={{ display: "flex", justifyContent: "flex-end", }}>

                    {/* 
                    <Grid item>
                        <button className="newBtn" onClick={previewContent} >Preview</button>
                    </Grid> */}

                    <Grid item>
                        <button className="newBtn" onClick={updateContent} >SAVE</button>
                    </Grid>

                </div>
            </NavPaper>



            <BackPaper>

                <div style={{ display: "flex", justifyContent: "space-between", }}>

                    <Grid container spacing={2} direction="column" justifyContent="flex-start" >
                        <Grid item>
                            <div style={{ fontSize: "17px", fontWeight: "500", color: "black", display: 'flex' }}>  Thumbnail <div style={{ color: 'red', fontSize: '15px', marginTop: '3px' }}>*</div> </div>
                            <input type="file" accept="image/*" onChange={(e) => imageChange(e)} id="imagechange" />
                        </Grid>
                        <Grid container spacing={2} justifyContent='space-between' style={{ margin: '0', width: '100%' }}>
                            <Grid item>
                                <div style={{ fontSize: "17px", fontWeight: "500", color: "black", display: 'flex' }}> Video File <div style={{ color: 'red', fontSize: '15px', marginTop: '3px' }}>*</div> </div>

                                <input type="file" disabled={!vidInput} accept="video/mp4" onChange={(e) => handleFileChange(e)} id="video" />
                                {completed ? (
                                    <div style={{ marginTop: 'auto' }}> {/* This div pushes CloudDone to the bottom */}
                                        <CloudDone sx={{ color: 'green' }} />
                                    </div>
                                ) : null}
                            </Grid>
                            <Grid item>
                                <div style={{ display: 'flex', flexDirection: 'column', height: '30px', width: '250px', marginTop: '12px' }}>
                                    {uploadProgress > 0 && (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <LinearWithValueLabel value={uploadProgress} />
                                            <div onClick={handleCancelUpload} ><Cancel sx={{ color: 'red', cursor: 'pointer' }} /></div>
                                        </div>
                                    )}

                                </div>
                            </Grid>
                        </Grid>



                        <Grid item>
                            <div style={{ fontSize: "17px", fontWeight: "500", color: "black", display: 'flex' }}> Title <div style={{ color: 'red', fontSize: '15px', marginTop: '3px' }}>*</div></div>
                            <div>
                                <input type="text" name="title" id="title" value={obj.title} min={5} onChange={inputEvent} onKeyDown={handleKeyDown} style={{ width: '200px' }} className="detailBarInput" />
                                <span style={{ position: 'absolute', bottom: '-20px', left: '0', fontSize: '12px', color: 'red' }}>
                                    Title must be at least 5 characters
                                </span>
                            </div>
                        </Grid>
                        {/* <Grid item>
                            <div style={{ fontSize: "17px", fontWeight: "500", color: "black", display: 'flex' }}> Vehicle <div style={{ color: 'red', fontSize: '15px', marginTop: '3px' }}>*</div></div>
                            <div>
                                
                                <select
                                    id="vehicle"
                                    className="detailBarInput"
                                    value={selectedVehicle} // Controlled component - value based on state
                                    onChange={handleSelectChange} // Call this function when the dropdown value changes
                                >
                                    <option value="">Select a Vehicle</option>
                                    {vehicleList.map((vehicleObj, index) => (
                                        <option key={index + 1} value={vehicleObj._id}>
                                            {vehicleObj._id}
                                        </option>
                                    ))}
                                </select>
                                
                            </div>
                        </Grid> */}

                        <Grid item>
                            <div style={{ fontSize: "17px", fontWeight: "500", color: "black", display: 'flex' }}> Description <div style={{ color: 'red', fontSize: '15px', marginTop: '3px' }}>*</div></div>
                            <textarea type="text" value={obj.description} required={true} name="description" placeholder="write atleast 25 characters..." id="description" onChange={inputEvent} onKeyDown={handleKeyDown} className="detailBarInput" />
                        </Grid>

                        <Grid item>
                            <div style={{ fontSize: "17px", fontWeight: "500", color: "black", display: 'flex' }}> Tags </div>
                            <input type="text"
                                value={inputValue}
                                onKeyDown={handleInputKeyDownTag}
                                onChange={handleInputChangeTag}
                                required={true}
                                placeholder='write your tags with comma separated, avoid using #'
                                name="description"
                                id="description"
                                className="detailBarInput" />

                            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '5px' }}>
                                {tags.map((tag, index) => (
                                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '5px', marginBottom: '5px', backgroundColor: '#f0f0f0', borderRadius: '5px', padding: '3px 8px' }}>
                                        <div style={{ marginRight: '5px' }}>{`#${tag}`}</div>
                                        <div ><Cancel sx={{ color: 'red', cursor: 'pointer', fontSize: '18px' }} onClick={() => removeTag(index)} /></div>
                                    </div>
                                ))}
                            </div>
                        </Grid>




                        {steps.length == 0 && ( // Show "Add another step +" only for the last object
                            <Grid item>
                                <span onClick={addStep} style={{ cursor: "pointer", fontSize: "17px", fontWeight: "500", color: "grey" }}>
                                    Add step by step guide  +
                                </span>
                            </Grid>
                        )}



                        {steps.map((option, index) => (
                            <>
                            
                                <Grid item>
                                    <div style={{ fontSize: "17px", fontWeight: "500", color: "black" }}>{`Step #${index + 1}`}</div>
                                </Grid>
                                <BackPaper style={{ height: 'auto', margin: '20px', backgroundColor: "#eeeeee" }} key={index}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Grid container spacing={2} direction="column" justifyContent="flex-start" >
                                            <Grid item>
                                                <div style={{ fontSize: "17px", fontWeight: "500", color: "black" }}> Thumbnail </div>
                                                {/* <input type="file" accept="image/*" onChange={(e) => StepimageChange(e)} id={`imagechange-${index}`} /> */}
                                                <input type="file" accept="image/*" onChange={(e) => StepimageChange(e)} id={`imagechange-${index}`} />

                                            </Grid>
                                            <Grid item>
                                                <div style={{ fontSize: "17px", fontWeight: "500", color: "black" }}>Guide Title</div>
                                                {/* <input type="text" name={`guide_title-${index}`} value={option.title} onChange={(e) => StepInputHandle(e, index)} style={{ width: '200px' }} className="detailBarInput" /> */}
                                                <input
                                                    type="text"
                                                    name={`guide_title`}
                                                    value={stepInputs[index]?.guide_title || ''}
                                                    onKeyDown={handleKeyDown}
                                                    onChange={(e) => handleStepInputChange(e, index)}
                                                    style={{ width: '200px' }}
                                                    className="detailBarInput"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <div>
                                                    <div style={{ fontSize: "17px", fontWeight: "500", color: "black" }}>Start time</div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <input
                                                            type="text"
                                                            name={`start_time_minutes`}
                                                            pattern="^[0-9]+$"
                                                            value={stepInputs[index]?.start_time_minutes || ''}
                                                            onChange={(e) => handleMinutesInputChange(e, index)}
                                                            maxLength={2}

                                                            placeholder="MM"
                                                            style={{ width: '40px', marginRight: '5px' }}
                                                            className="detailBarInput"
                                                        />
                                                        <span>:</span>
                                                        <input
                                                            type="text"
                                                            name={`start_time_seconds`}
                                                            value={stepInputs[index]?.start_time_seconds || ''}
                                                            onChange={(e) => handleSecondsInputChange(e, index)}
                                                            maxLength={2}
                                                            pattern="[0-5][0-9]|60"
                                                            placeholder="SS"
                                                            style={{ width: '40px', marginLeft: '5px' }}
                                                            className="detailBarInput"
                                                        />
                                                    </div>
                                                </div>
                                               
                                            </Grid>
                                            <Grid item>
                                                <button className='stepsButton' style={{ padding: '0px 15px' }} onClick={() => removeOption(index)}>-</button>
                                            </Grid>

                                            {index === steps.length - 1 && ( // Show "Add another step +" only for the last object
                                                <Grid item>
                                                    <span onClick={addStep} style={{ cursor: "pointer", fontSize: "17px", fontWeight: "500", color: "#74c168" }}>
                                                        Add another step  +
                                                    </span>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </div>
                                </BackPaper>
                            </>))}


                        <Grid item>
                            <span onClick={addPart} style={{ cursor: "pointer", fontSize: "17px", fontWeight: "500", color: "grey" }}>
                                Add Parts  +
                            </span>
                        </Grid>

                        {parts.map((option, index) => (
                            <>
                                <Grid item>
                                    <div style={{ fontSize: "17px", fontWeight: "500", color: "black" }}>{`Part #${index + 1}`}</div>
                                </Grid>
                                <Grid item>
                                    {/* <input type="text" name={`start_time-${index}`} value={partInputs[index].index} onChange={(e) => handlePartInputChange(e, index)} style={{ width: '200px' }} className="detailBarInput" /> */}
                                    <input
                                        type="text"
                                        name={`start_time-${index}`}
                                        value={partInputs[index]?.[index] || ''}
                                        onChange={(e) => handlePartInputChange(e, index)}
                                        style={{ width: '200px' }}
                                        className="detailBarInput"
                                    />
                                </Grid>
                                <Grid item>
                                    <button style={{ padding: '0px 15px' }} onClick={() => removePart(index)}>-</button>
                                </Grid>
                            </>
                        ))}



                    </Grid>
                </div>
            </BackPaper>
        </div>
    )
}

export default AddVideos