import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Grid, Paper, Typography, styled, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Dialog, DialogTitle, DialogActions, Button, Slide, DialogContent, DialogContentText, TextField } from '@mui/material'
import { toast, ToastContainer } from 'react-toastify'
import { DeleteOutline, DoneAll, PhotoOutlined, Mode, RemoveRedEye, RemoveRedEyeOutlined } from '@mui/icons-material'
import Loader from '../../components/loader/Loader'
import Toptag from '../../components/topTag/Toptag'
import APIFunction from "../../ApiFunction"
import { BASE_URL } from '../../Api'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import APIFunctions from "../../ApiFunction";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios'



const NavPaper = styled(Paper)({
    padding: "10px",
    borderRadius: "0",
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const BackPaper = styled(Paper)({
    height: "80vh",
    overflow: "auto",
    // padding: "20px",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
});
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const Videos = () => {
    const [all, setAll] = useState([]);
    const [initialData, setInitialData] = useState([]);
    const [filterByAdmin, setFilterByAdmin] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [open, setOpen] = useState({ create: "", edit: "" });
    const [obj, setObj] = useState({ pic: "", que: "" })
    const [error, setError] = useState(false);
    const [disable, setDisable] = useState(false);
    const [image, setImage] = useState("");
    const [image2, setImage2] = useState("");
    const [putObjurl, setPutObjurl] = useState({ url: '', key: '' });
    const [del, setDel] = React.useState(false);
    const [id, setId] = useState()


    const handleDeleteOpen = (id, status) => {
        setId(id)
        setDel(true)

    };

    const handleDel = () => {
        setDel(false);
    };

    const deleteButton = async (id) => {
        const result = await APIFunction.DELETE_VIDEO(id);
        getAll();
        if (result.data.code === 200) {
            setDel(false);
            toast.success("Successfully deleted!");
        } else if (result.data.code === 201) {
            toast.error("Please try again!");
        } else if (result.data.code === 203) {
            localStorage.clear('admintoken');
            sessionStorage.clear('admintoken');
            navigate("/");
            toast.info("Session Expired. Please log in again!");
        }
    }



    const getAll = async () => {
        try {
            let result = await APIFunctions.getAllVideos();
            console.log(result)
            if (result.data.code === 200) {
                setAll(result.data.result)
                setIsLoading(false)
                setInitialData(result.data.result)
            } else if (result.data.code === 203) {
                toast.info("Session Expired. Please login again!!");
                localStorage.clear();
                navigate("/");
                window.location.reload();

            }
        } catch (err) {
            console.log(err.message)
        }
    }
    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        setFilterByAdmin(isChecked);

        if (isChecked) {
            const filtered = all.filter((item) => item.byAdmin === true);
            setAll(filtered);
        } else {
            setAll(initialData);
        }
    };

    useEffect(() => {
        getAll();
    }, [])


    return (
        <>
            {
                isLoading ? (
                    <Loader />) : (

                    <>
                        <Toptag />
                        <NavPaper>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div></div>
                                <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
                                    <Grid item>
                                        <FormControlLabel
                                            control={<Checkbox sx={{color: 'blue'}} checked={filterByAdmin} onChange={handleCheckboxChange} />}
                                            label="Admin videos only"
                                        />
                                    </Grid>
                                    <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Total - {all.length}</div></Grid>

                                    {/* <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Add new video:</div></Grid> */}

                                    <Grid item>
                                        <NavLink to="/videos/add-videos" className="link">
                                            <button className='newBtn' >ADD NEW</button>
                                        </NavLink>
                                    </Grid>
                                </Grid>

                            </div>
                        </NavPaper>
                        <BackPaper>
                            {all.length === 0 ?
                                <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                                    <Grid item><img src="/images/no-data.png" alt="error-image" style={{ width: "100%", height: '440px' }} /></Grid>
                                    <Grid item><div className="errorMessage">No Video added yet! Click on Create button to add one.</div></Grid>
                                </Grid> :
                                <>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow className='tableHead'>
                                                <TableCell>S.No</TableCell>
                                                <TableCell>Thumbnail</TableCell>
                                                <TableCell>Title</TableCell>
                                                <TableCell>Duration(MM:SS)</TableCell>
                                                <TableCell align="center">Added On</TableCell>
                                                <TableCell align="center">Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {all.map((element, index) => {
                                                let date = new Date(element.created_at);
                                                let datearr = ["Jan", "Feb", "March", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec",];
                                                let newDate = date.toISOString().substring(0, 10).split("-")[2] + " " +
                                                    datearr[parseInt(date.toISOString().substring(0, 10).split("-")[1]) - 1] + " " +
                                                    date.toISOString().substring(0, 10).split("-")[0];
                                                return (
                                                    <TableRow className='tableData' key={index + 1}>
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>
                                                            {
                                                                <img src={element.thumbnail && element.thumbnail !== "" ? `${element.thumbnail}` : '/images/no_image.png'} alt="user" style={{ width: "100px", height: "60px" }} />
                                                            }
                                                        </TableCell>
                                                        <TableCell>{element.title}</TableCell>
                                                        <TableCell>{element.video_length}</TableCell>
                                                        <TableCell align="center">{newDate}</TableCell>


                                                        <TableCell align="right">
                                                            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" minWidth='180px'>

                                                                <Grid item>
                                                                    <Tooltip title="watch video" placement="top" >
                                                                        <NavLink to='/videos/details'><RemoveRedEyeOutlined onClick={() => localStorage.setItem("video_id", element._id)} className='visibilityIc' /></NavLink>
                                                                    </Tooltip>
                                                                </Grid>

                                                                <Grid item>
                                                                    {/* <Tooltip title="Edit" placement="top" > */}
                                                                    <NavLink to='/videos/edit'>
                                                                        <Mode onClick={() => localStorage.setItem("video_id", element._id)} className='visibilityIc' />
                                                                    </NavLink>
                                                                    {/* </Tooltip> */}
                                                                </Grid>



                                                                <Grid item>
                                                                    {/* <Tooltip title={`Delete`} placement="top"> */}
                                                                    <DeleteOutline className="userListDelete" onClick={() => { handleDeleteOpen(element._id) }} />
                                                                    {/* </Tooltip> */}
                                                                </Grid>

                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>

                                </>}


                            {/* dilogue for delete  */}
                            <Dialog open={del} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                                <DialogTitle id="alert-dialog-title">
                                    {"Are you sure want to delete this Trivia Question?"}
                                </DialogTitle>
                                <DialogActions>
                                    <Button variant='outlined' onClick={handleDel}>No</Button>
                                    <Button variant='outlined' onClick={() => { deleteButton(id) }} >Yes</Button>
                                </DialogActions>
                            </Dialog>
                        </BackPaper>
                    </>

                )
            }

        </>
    )
}

export default Videos;









