import { Edit, RemoveCircleOutline, RemoveRedEye, ToggleOff, ToggleOn, } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Pagination, Paper, Select, Slide, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/loader/Loader'
import Toptag from '../../components/topTag/Toptag'
import APIFunction from "../../ApiFunction"
import { BASE_URL } from '../../Api'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const NavPaper = styled(Paper)({
  padding: "10px",
  borderRadius: "0",
  backgroundColor: "white",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const BackPaper = styled(Paper)({
  height: "70vh",
  overflow: "auto",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const ContactOptions = () => {
  const [all, setAll] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [postCount, setPostCount] = useState()
  const [filterPost, setFilterPost] = useState('')
  const [open, setOpen] = useState({ create: "", edit: "" });
  const [remopen, setRemOpen] = useState(false);
  const [id, setId] = useState()
  const [obj,setObj] = useState({content:''})
  const [error, setError] = useState(false);
  const [disable,setDisable] = useState(false)
  const handleCreate = (e) =>{
    setError(false)
    setObj({
      ...obj,
      [e.target.name]: e.target.value,
    })
  }
  const cancelOption = () => {
    setOpen({})
    setError(false)
    setObj({content:''})
  }

  const editOpenOption = (id,text) =>{
    setOpen({edit:'true'})
    setId(id)
    setObj({content:text})
  }
 





  const getAll = async () => {
    setIsLoading(true)
    const result = await APIFunction.getAllcontactOptions();
    if (result.data.code === 200) {
      setAll(result.data.result)
      setPostCount(result.data.countPost)
      setIsLoading(false)
    } else if (result.data.code === 203) {
      toast.info("Session Expired. Please login again!!");
        localStorage.clear();
        navigate("/")
        window.location.reload();
    }
  }
  
  const createOneOption = async () => {
    try {
      if(obj.content===''){
        setError(true)
      }else{
        setDisable(true)
        const result = await APIFunction.addContactUsOptions(obj);
        if (result.data.code === 200) {
          setOpen({});
          getAll();
          setIsLoading(false)
          setObj({content:''})
          setError(false)
          toast.success("Create Successfully!")
          setDisable(false)
        } else if (result.data.code === 201) {
          toast.error("Please try again!")
          setDisable(false)
        } else if (result.data.code === 203) {
          toast.info("Session Expired. Please login again!!");
        localStorage.clear();
        navigate("/")
        window.location.reload();
        }
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  const editOneOption = async () => {
    let data = {
      id:id,
      content:obj.content
    }
    try {
      if(obj.content===''){
        setError(true)
      }else{
        setDisable(true)
        const result = await APIFunction.editontactUsOptions(data);
        if (result.data.code === 200) {
          setOpen({});
          getAll();
          setIsLoading(false)
          setObj({content:''})
          setError(false)
          toast.success("Create Successfully!")
          setDisable(false)
        } else if (result.data.code === 201) {
          toast.error("Please try again!")
          setDisable(false)
        } else if (result.data.code === 203) {
          localStorage.clear('admintoken');
          sessionStorage.clear('admintoken');
          navigate("/")
          toast.info("Session Expired Please login again!!")
        }
      }
    } catch (error) {
      console.log(error.message)
    }
  }
  
  useEffect(() => {
    getAll()
  }, [])

  return (
    <>


      {
        isLoading ? (
          <Loader />) : (
          <>
            <Toptag />
            <ToastContainer  autoClose={1000}/>
            <NavPaper>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div></div>
                <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
                  <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Total Contact option:- {all.length}</div></Grid>
                  <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Add New Option:</div></Grid>
                  <Grid item>
                    <button className='newBtn' onClick={() => setOpen({ create: "true" })}>Create</button>
                  </Grid>


                </Grid>

              </div>
            </NavPaper>
            <BackPaper>
              {postCount === 0 ?
                <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                  <Grid item><img src="/images/no-data.png" alt="error-image" style={{ height: "400px", width: "400px" }} /></Grid>
                  <Grid item><div className="errorMessage">No FlashCard Created Ye!</div></Grid>
                </Grid> :
                <>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>S.No</TableCell>
                        <TableCell >Contact Option</TableCell>
                        <TableCell >Created On</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {all.map((element, index) => {
                        let date = new Date(element.created_at);
                        let datearr = ["Jan", "Feb", "March", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec",];
                        let newDate = date.toISOString().substring(0, 10).split("-")[2] + " " +
                          datearr[parseInt(date.toISOString().substring(0, 10).split("-")[1]) - 1] + " " +
                          date.toISOString().substring(0, 10).split("-")[0];
                        return (<>
                          <TableRow key={index + 1}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{element.content}</TableCell>
                            <TableCell>{newDate}</TableCell>
                            <TableCell align="right">
                              <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                                <Grid item>
                                  <Tooltip title="Edit this option">
                                    <Edit onClick={()=>editOpenOption(element._id,element.content)} className='visibilityIc' />
                                  </Tooltip>
                                </Grid>
                                <Grid item></Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        </>
                        )

                      })}
                    </TableBody>
                  </Table>

                </>
              }


              {/* dilogue for create option */}
              <Dialog open={open.create === "true"} TransitionComponent={Transition} >
                <DialogTitle>Create a New Option</DialogTitle>
                <DialogContent>
                  <DialogContentText style={{marginBottom:"10px"}}>
                  Provide details about the option and click the 'Create' button to generate the new option.
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="content"
                    type="text"
                    multiline
                    name="content"
                    error={error?true:false}
                    helperText={error?"Feild is required":null}
                    onChange={handleCreate}
                    fullWidth
                    variant="standard"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={cancelOption} >Cancel</Button>
                  <Button onClick={createOneOption} disabled={disable}>Create</Button>
                </DialogActions>
              </Dialog>

              {/* dilogue for edit option */}
              <Dialog open={open.edit === "true"} TransitionComponent={Transition} >
                <DialogTitle><h4>EDIT</h4></DialogTitle>
                <DialogContent>
                  <DialogContentText style={{marginBottom:"10px"}}>
                   Please edit the below text and press the save button to preserve your modifications.
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    type="text"
                    value={obj.content}
                    name="content"
                    error={error?true:false}
                    helperText={error?"Feild is required":null}
                    multiline
                    fullWidth
                    onChange={handleCreate}
                    variant="standard"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={cancelOption}>Cancel</Button>
                  <Button onClick={editOneOption} disabled={disable}>Save</Button>
                </DialogActions> 
              </Dialog>
            </BackPaper>
          </>

        )}

    </>
  )
}

export default ContactOptions









