

import React, { useEffect, useState } from "react";
import Toptag from "../../components/topTag/Toptag";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Grid, Paper, styled } from "@mui/material";
import APIFunctions from "../../ApiFunction";
import axios from "axios";
import { Cancel } from "@mui/icons-material";

import Loader from "../../components/loader/Loader";



const BackPaper = styled(Paper)({
    height: "80vh",
    overflow: "auto",
    padding: "10px",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const NavPaper = styled(Paper)({
    padding: "5px",
    borderRadius: "0",
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const EditVideos = () => {
    const id = localStorage.getItem('video_id');
    const [steps, setSteps] = useState([]);
    const [parts, setParts] = useState([]);
    const [putObjurl, setPutObjurl] = useState('');
    const [type, setType] = useState(3)

    const [obj, setObj] = useState({ video: "", description: "", title: "", steps: [], parts: [], tags: [] })

    const navigate = useNavigate();

    const [img, setImg] = useState("")

    const [stepInputs, setStepInputs] = useState([]);
    const [partInputs, setPartInputs] = useState([]);

    const [vidInput, setVidInput] = useState(true);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [completed, setCompleted] = useState(false);
    const [xhrInstance, setXhrInstance] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [inputValue, setInputValue] = useState('');




    const getPutObjUrlImage = async (contentType) => {
        const result = await APIFunctions.putObjectUrl(contentType);
        return result;
    }
    const getPutObjUrlImageSteps = async (contentType) => {
        const result = await APIFunctions.putObjectUrl(contentType);
        return result;
    }



    const imageChange = async (e) => {
        setImg(e.target.files[0]);
        if (e.target.files && e.target.files.length > 0) {
            let url = await getPutObjUrlImage(e.target.files[0].type); // getting the url here
            setPutObjurl(url.data.result.key);
            if (url.data.code == 200) {
                await axios.put(url.data.result.url, e.target.files[0], {
                    headers: {
                        'Content-Type': e.target.files[0].type
                    }
                });

                setObj(prevState => {
                    return { ...prevState, thumbnail: url.data.result.key };
                });
            } else {
                return window.alert("Oops! Something went wrong.");
            }
        }
    };


    const handleKeyDown = e => {
        if (e.target.value === "" && e.key === " ") {
            e.preventDefault();
        }
    };
    const inputEvent = (e) => {
        setObj({
            ...obj,
            [e.target.name]: e.target.value
        });
    };





    const updateContent = async () => {
        try {
          
            const invalidSteps = obj.steps.filter((item, index) => {
                return item.guide_title === "" || item.start_time === "" || item.image === '';
            });
        
            if (invalidSteps.length > 0) {
                const invalidStepIndexes = invalidSteps.map((step, index) => index + 1);
                window.alert(`Step(s) have missing information.`);
                return;
            }
            if (obj.parts.some(e => e === "")) {
                window.alert("Missing information in parts");
                return; // This return statement will exit the entire function if any empty string is found in obj.parts.
            }
            

            if (!obj.title) { alert("Title is required."); return; }
            if (obj.title.length < 5) { alert("Title must contain atleast 5 characters"); return; }

            if (!obj.description) { alert("Description is required."); return; }
            if (obj.description.length < 25) { alert("Description must contain atleast 25 characters"); return; }
            console.log(obj, 'obj')
            let result = await APIFunctions.UPDATE_VIDEOS(obj);

            if (result.data.code === 200) {
                toast.success("Successfully Added!")
                navigate('/videos')
            } else if (result.data.code === 203) {
                toast.info("Session Expired. Please login again!!");
                localStorage.clear();
                navigate("/")
                window.location.reload();
            }
            // }
        } catch (err) {
            console.log(err.message)
            window.alert('Something went wrong!');
            navigate('/')
        }
    }

    const addStep = () => {
        setSteps((prevOptions) => [...prevOptions, {}]);
    }
    const addPart = () => {
        setParts((prevOptions) => [...prevOptions, {}]);
    }
    const removeOption = (index) => {
        const newSteps = [...steps];
        const newStepInputs = [...stepInputs];

        newSteps.splice(index, 1); // Remove the step at the specified index
        newStepInputs.splice(index, 1); // Remove the step input at the specified index

        setSteps(newSteps);
        setStepInputs(newStepInputs);
    };

    const removePart = (indexToRemove) => {
        setObj(prevState => {
            const updatedParts = prevState.parts.filter((part, index) => index !== indexToRemove);
            return { ...prevState, parts: updatedParts };
        });
    };
    const removeStep = (indexToRemove) => {
        setObj(prevState => {
            const updatedSteps = prevState.steps.filter((step, index) => index !== indexToRemove);
            return { ...prevState, steps: updatedSteps };
        });
    };


    const addNewStep = () => {
        setObj(prevState => {
            const updatedSteps = [
                ...prevState.steps,
                { guide_title: '', start_time: '', image: '' }
            ];
            return { ...prevState, steps: updatedSteps };
        });
    };

    const addNewPart = () => {
        setObj(prevState => {
            const updatedParts = [...prevState.parts, ''];
            return { ...prevState, parts: updatedParts };
        });
    };

    const handleStepChange = (index, name, value) => {
        console.log('stepc change')
        setObj(prevState => {
            const updatedSteps = prevState.steps.map((step, i) => {
                if (i === index) {
                    return {
                        ...step,
                        [name]: value
                    };
                }
                return step;
            });

            return { ...prevState, steps: updatedSteps };
        });
    };

    const StepimageChange = async (e) => {
        console.log('stepImage change')

        if (e.target.files && e.target.files.length > 0) {
            let url = await getPutObjUrlImageSteps(e.target.files[0].type);

            if (url.data.code == 200) {
                await axios.put(url.data.result.url, e.target.files[0], {
                    headers: {
                        'Content-Type': e.target.files[0].type
                    }
                });
                console.log(e.target.name, "name")
                setObj(prevState => {
                    const updatedSteps = prevState.steps.map((step, i) => {

                        if (i == parseInt(e.target.name)) {
                            return {
                                ...step,
                                image: url.data.result.key
                            };
                        }
                        return step;
                    });

                    return { ...prevState, steps: updatedSteps };
                });
            } else {
                return window.alert("Oops! Something went wrong.");
            }
        }

    };

    const handlePartChange = (index, value) => {
        setObj(prevState => {
            const updatedParts = [...prevState.parts];
            updatedParts[index] = value;
            return { ...prevState, parts: updatedParts };
        });
    };

    const handleInputChangeTag = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputKeyDownTag = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            const tag = inputValue.trim().toLowerCase();
            if (tag) {
                setObj(prevState => {
                    const updatedTags = [...prevState.tags, tag];
                    return { ...prevState, tags: updatedTags };
                });
                setInputValue(''); // Clear the input value after adding the tag
            }
        } else if (e.key === 'Backspace' && inputValue === '') {
            // Remove the last tag when backspace is pressed and the input is empty
            setObj(prevState => {
                const updatedTags = [...prevState.tags];
                updatedTags.pop(); // Remove the last tag from the array
                return { ...prevState, tags: updatedTags };
            });
        }
    };

    const removeTag = (indexToRemove) => {
        setObj(prevState => {
            const updatedTags = prevState.tags.filter((_, index) => index !== indexToRemove);
            return { ...prevState, tags: updatedTags };
        });
    };

    const getAll = async () => {
        const result = await APIFunctions.GET_VIDEO_DETAIL_EDITSCREEN(id);
        if (result.data.code === 200) {
            setObj(result.data.result)
            setIsLoading(false)
        } else if (result.data.code === 201) {
            toast.error("error are coming for fetching images")
        } else if (result.data.code === 203) {
            window.location.reload()
            localStorage.clear();
            navigate("/")
            toast.info("Session Expired Please login again!!")
        }
    }


    useEffect(() => {
        getAll();
    }, [])




    return (
        <>
            {
                isLoading ? (
                    <Loader />) : (

                    <>
                        <div className='container' >
                            <Toptag />

                            <NavPaper>
                                <div style={{ display: "flex", justifyContent: "flex-end", }}>

                                    {/* 
                    <Grid item>
                        <button className="newBtn" onClick={previewContent} >Preview</button>
                    </Grid> */}

                                    <Grid item>
                                        <button className="newBtn" onClick={updateContent} >UPDATE</button>
                                    </Grid>

                                </div>
                            </NavPaper>



                            <BackPaper>

                                <div style={{ display: "flex", justifyContent: "space-between", }}>

                                    <Grid container spacing={2} direction="column" justifyContent="flex-start" >
                                        <Grid item>
                                            <div style={{ fontSize: "17px", fontWeight: "500", color: "black", display: 'flex' }}>  Thumbnail <div style={{ color: 'red', fontSize: '15px', marginTop: '3px' }}>*</div> </div>
                                            <img src={obj.thumbnail_view} style={{ height: '40px', width: '40px', margin: '0 50px 0 2px' }} alt="" srcset="" />
                                            <input type="file" accept="image/*" onChange={(e) => imageChange(e)} id="imagechange" />
                                        </Grid>
                                        {/* <Grid container spacing={2} justifyContent='space-between' style={{ margin: '0', width: '100%' }}>


                        </Grid> */}

                                        <Grid item>
                                            <div style={{ fontSize: "17px", fontWeight: "500", color: "black", display: 'flex' }}> Title <div style={{ color: 'red', fontSize: '15px', marginTop: '3px' }}>*</div></div>
                                            <div>
                                                <input type="text" name="title" id="title" value={obj.title} min={5} onChange={inputEvent} onKeyDown={handleKeyDown} style={{ width: '200px' }} className="detailBarInput" />
                                                <span style={{ position: 'absolute', bottom: '-20px', left: '0', fontSize: '12px', color: 'red' }}>
                                                    Title must be at least 5 characters
                                                </span>
                                            </div>
                                        </Grid>

                                        <Grid item>
                                            <div style={{ fontSize: "17px", fontWeight: "500", color: "black", display: 'flex' }}> Description <div style={{ color: 'red', fontSize: '15px', marginTop: '3px' }}>*</div></div>
                                            <textarea type="text" value={obj.description} required={true} name="description" id="description" onChange={inputEvent} onKeyDown={handleKeyDown} className="detailBarInput" />
                                        </Grid>

                                        <Grid item>
                                            <div style={{ fontSize: "17px", fontWeight: "500", color: "black", display: 'flex' }}> Tags </div>
                                            <input type="text"
                                                value={inputValue}
                                                onKeyDown={handleInputKeyDownTag}
                                                onChange={handleInputChangeTag}
                                                required={true}
                                                placeholder='write your tags with comma separated, avoid using #'
                                                name="description"
                                                id="description"
                                                className="detailBarInput" />

                                            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '5px' }}>
                                                {obj.tags && obj.tags.map((tag, index) => (
                                                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '5px', marginBottom: '5px', backgroundColor: '#f0f0f0', borderRadius: '5px', padding: '3px 8px' }}>
                                                        <div style={{ marginRight: '5px' }}>{`#${tag}`}</div>
                                                        <div ><Cancel sx={{ color: 'red', cursor: 'pointer', fontSize: '18px' }} onClick={() => removeTag(index)} /></div>
                                                    </div>
                                                ))}
                                            </div>
                                        </Grid>




                                        {obj.steps.map((option, index) => (
                                            <>
                                                <Grid item>
                                                    <div style={{ fontSize: "17px", fontWeight: "500", color: "black" }}>{`Step #${index + 1}`}</div>
                                                </Grid>
                                                <BackPaper style={{ height: 'auto', margin: '20px', backgroundColor: "#eeeeee" }} key={index}>
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <Grid container spacing={2} direction="column" justifyContent="flex-start" >

                                                            <Grid item>
                                                                <div style={{ fontSize: "17px", fontWeight: "500", color: "black" }}> Thumbnail </div>
                                                                {option.image_view && option.image_view !== "" ? (<img src={option.image_view} style={{ height: '40px', width: '40px', margin: '0 50px 0 2px' }} alt="" srcset="" />) : null}
                                                                <input type="file" accept="image/*" onChange={(e) => StepimageChange(e)} id={`imagechange-${index}`} name={`${index}`} />

                                                            </Grid>

                                                            <Grid item>
                                                                <div style={{ fontSize: "17px", fontWeight: "500", color: "black" }}>Guide Title</div>
                                                                {/* <input type="text" name={`guide_title-${index}`} value={option.title} onChange={(e) => StepInputHandle(e, index)} style={{ width: '200px' }} className="detailBarInput" /> */}
                                                                <input
                                                                    type="text"
                                                                    name={`guide_title-${index}`}
                                                                    onKeyDown={handleKeyDown}
                                                                    value={option.guide_title}
                                                                    onChange={(e) => handleStepChange(index, 'guide_title', e.target.value)}
                                                                    style={{ width: '200px' }}
                                                                    className="detailBarInput"
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <div style={{ fontSize: "17px", fontWeight: "500", color: "black" }}>Start time</div>

                                                                <input
                                                                    type="text"
                                                                    name={`start_time-${index}`}
                                                                    value={option.start_time}
                                                                    onChange={(e) => handleStepChange(index, 'start_time', e.target.value)}
                                                                    maxLength={5}
                                                                    pattern="\d{0,2}:\d{0,2}"
                                                                    placeholder="MM:SS"
                                                                    style={{ width: '80px' }}
                                                                    className="detailBarInput"
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <button className='stepsButton' style={{ padding: '0px 15px' }} onClick={() => removeStep(index)}>-</button>
                                                            </Grid>

                                                            {index === steps.length - 1 && ( // Show "Add another step +" only for the last object
                                                                <Grid item>
                                                                    <span onClick={addStep} style={{ cursor: "pointer", fontSize: "17px", fontWeight: "500", color: "#74c168" }}>
                                                                        Add another step  +
                                                                    </span>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </div>
                                                </BackPaper>
                                            </>))}

                                        {steps.length == 0 && ( // Show "Add another step +" only for the last object
                                            <Grid item>
                                                <span onClick={addNewStep} style={{ cursor: "pointer", fontSize: "17px", fontWeight: "500", color: "green" }}>
                                                    Add new steps  +
                                                </span>
                                            </Grid>
                                        )}



                                        {obj.parts.map((option, index) => (
                                            <>
                                                <Grid item>
                                                    <div style={{ fontSize: "17px", fontWeight: "500", color: "black" }}>{`Part #${index + 1}`}</div>
                                                </Grid>
                                                <Grid item>
                                                    {/* <input type="text" name={`start_time-${index}`} value={partInputs[index].index} onChange={(e) => handlePartInputChange(e, index)} style={{ width: '200px' }} className="detailBarInput" /> */}
                                                    <input
                                                        type="text"
                                                        name={`part-${index}`}
                                                        value={option}
                                                        onChange={(e) => handlePartChange(index, e.target.value)}
                                                        style={{ width: '200px' }}
                                                        className="detailBarInput"
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <button style={{ padding: '0px 15px' }} onClick={() => removePart(index)}>-</button>
                                                </Grid>
                                            </>
                                        ))}

                                        <Grid item>
                                            <span onClick={addNewPart} style={{ cursor: "pointer", fontSize: "17px", fontWeight: "500", color: "green" }}>
                                                Add new parts  +
                                            </span>
                                        </Grid>

                                    </Grid>
                                </div>
                            </BackPaper>
                        </div>
                    </>
                )
            }
        </>

    )
}

export default EditVideos