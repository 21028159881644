import { AddToPhotos, Block, DeleteOutline, DeleteOutlined, FreeBreakfastSharp, GppMaybeOutlined, Person, PhotoOutlined, VerifiedOutlined, WorkspacePremium } from '@mui/icons-material'
import { Chip, Grid, Paper, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import APIFunctions from '../../ApiFunction'
import Toptag from '../../components/topTag/Toptag';
import "./userDetail.css"
import Loader from '../../components/loader/Loader'
import { BASE_URL, Img_url } from '../../Api'

const BackPaper = styled(Paper)({
  height: "80vh",
  borderRadius: "0",
  padding: "20px",
  overflow: "auto",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const UserDetail = () => {
  const id = localStorage.getItem('user_id');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [edit, setEdit] = useState(false)
  const [disable, setDisable] = useState(false)
  const [obj, setObj] = useState(
    { user_name: "", email: "", contact: "", profile_pic: "" }
  )

  const inputEvent = (e) => {
    setObj({
      ...obj,
      [e.target.name]: e.target.value
    });
  };


  const getAll = async () => {
    const result = await APIFunctions.getSinleUserDetail(id);
    if (result.data.code === 200) {
      setObj(result.data.result)
      setIsLoading(false)
    } else if (result.data.code === 201) {
      toast.error("error are coming for fetching images")
    } else if (result.data.code === 203) {
      window.location.reload()
      localStorage.clear();
      navigate("/")
      toast.info("Session Expired Please login again!!")
    }
  }
  const updateUser = async () => {
    let data = {
      id: id,
      user_name: obj.user_name,
      email: obj.email,
      contact: obj.contact
    }
    setDisable(true)
    const result = await APIFunctions.updateUserDetails(data);
    if (result.data.code === 200) {
      setIsLoading(false)
      setEdit(false)
      toast.success("User update successfully!")
      setDisable(true)
    } else if (result.data.code === 201) {
      toast.error(result.data.message)
      setDisable(false)
    } else if (result.data.code === 203) {
      toast.info("Session Expired. Please login again!!");
      localStorage.clear();
      navigate("/")
      window.location.reload();
    }
  }


  useEffect(() => {
    setIsLoading(true)
    getAll()
  }, [])

  return (
    <>
      {isLoading ?
        <Loader />
        :
        <>
          <Toptag />
      
          <BackPaper>

            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item style={{ fontSize: "20px", fontWeight: "400", color: "grey" }}>
                User Profile Details
              </Grid>
            </Grid>

            <hr></hr>

            <Grid container >
              <Grid xs={12} sm={12} md={6} lg={5} item >

                {/* <Grid container spacing={3} direction="column" justifyContent="flex-start" alignItems="center"> */}
                  <Grid item>
                    {
                      <img src={obj.profile_pic && obj.profile_pic !== "" ? `${obj.profile_pic}` : '/images/blank_pic.png'}
                        alt=""
                        className='imgCont1' />
                    }
                  </Grid>
                {/* </Grid> */}

              </Grid>

              <Grid xs={12} sm={12} md={6} lg={7} item>
                <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="flex-start">

                  <Grid item>Name</Grid>
                  <Grid item style={{ width: "100%" }}>
                    <input type="text" disabled={!edit} value={obj.user_name} name="user_name" id="user_name" onChange={inputEvent} className="detailBarInput" />
                  </Grid>

                  <Grid item>Email</Grid>
                  <Grid item style={{ width: "100%" }}>
                    <input type="text" disabled={!edit} value={obj.email} name="email" id="email" onChange={inputEvent} className="detailBarInput" />
                  </Grid>

                  <Grid item>Contact</Grid>
                  <Grid item style={{ width: "100%" }}>
                    <input type="text" disabled={!edit} value={obj.contact !== "" ? `${obj.country_code} ${obj.contact}`: "N/A"} name="contact" id="contact" onChange={inputEvent} className="detailBarInput" />
                  </Grid>

                  <Grid item>Address</Grid>
                  <Grid item style={{ width: "100%" }}>
                    <input type="text" disabled={!edit} value={obj.address !== "" ? `${obj.address}`: "N/A"} name="contact" id="contact" onChange={inputEvent} className="detailBarInput" />
                  </Grid>

                  <Grid item>Status</Grid>
                  <Grid item style={{ width: "100%" }}>
                    {obj.isAccDeleted === true ?
                      <div className='purchaseChip'>
                        <Block style={{ color: "red" }} />
                        <div>Inactive</div>
                      </div>
                      :
                      <div className='purchaseChip'>
                        <Person style={{ color: "grey" }} />
                        <div>Active</div>
                      </div>
                    }
                  </Grid>
                  <Grid item>Email Verification Status</Grid>
                  <Grid item style={{ width: "100%" }}>
                    {obj.emailVerified === true ?
                      <div className='purchaseChip'>
                        <VerifiedOutlined style={{ color: "green" }} />
                        <div>Verfied</div>
                      </div>
                      :
                      <div className='purchaseChip'>
                        <GppMaybeOutlined style={{ color: "red" }} />
                        <div>Not Verfied</div>
                      </div>
                    }
                  </Grid>
                  {/* <Grid item>Contact Verified</Grid>
                  <Grid item style={{ width: "100%" }}>
                    {obj.contactVerified === true ?
                      <div className='purchaseChip'>
                        <VerifiedOutlined style={{ color: "green" }} />
                        <div>Verfied</div>
                      </div>
                      :
                      <div className='purchaseChip'>
                        <GppMaybeOutlined style={{ color: "red" }} />
                        <div>Not Verfied</div>
                      </div>
                    }
                  </Grid>
                  <Grid item>Contact</Grid>
                  <Grid item style={{ width: "100%" }}>
                    <input type="text" disabled value={obj.country_code + obj.contact}  className="detailBarInput" />
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>

          </BackPaper>
        </>}

    </>
  )
}

export default UserDetail