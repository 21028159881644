import { AddToPhotos, AssignmentLateOutlined, Block, DeleteOutline, DeleteOutlined, ErrorOutlineOutlined, FileCopyOutlined, FreeBreakfastSharp, GppMaybeOutlined, Person, PhotoOutlined, VerifiedOutlined, WarningOutlined, WorkspacePremium } from '@mui/icons-material'
import { Chip, Grid, Paper, Button,  Dialog, DialogTitle,DialogContent,DialogActions } from '@mui/material'
import { styled } from '@mui/system'
import SwipeableTextMobileStepper from '../../components/carousel/Carousel';

import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import APIFunctions from '../../ApiFunction'
import Toptag from '../../components/topTag/Toptag';
import "./userDetail.css"
import Loader from '../../components/loader/Loader'
import { BASE_URL, Img_url } from '../../Api'
import TextMobileStepper from '../../components/carousel/Carousel';

const BackPaper = styled(Paper)({
    height: "80vh",
    borderRadius: "0",
    padding: "20px",
    overflow: "auto",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const TechnicianDetail = () => {
    const id = localStorage.getItem('user_id');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
    const [edit, setEdit] = useState(false)
    const [disable, setDisable] = useState(false)
    const [obj, setObj] = useState(
        { user_name: "", email: "", contact: "", profile_pic: "" }
    )

    const inputEvent = (e) => {
        setObj({
            ...obj,
            [e.target.name]: e.target.value
        });
    };
    


    const getAll = async () => {
        const result = await APIFunctions.getSinleUserDetail(id);
        if (result.data.code === 200) {
            setObj(result.data.result)
            setIsLoading(false)
        } else if (result.data.code === 201) {
            toast.error("error are coming for fetching images")
        } else if (result.data.code === 203) {
            window.location.reload()
            localStorage.clear();
            navigate("/")
            toast.info("Session Expired Please login again!!")
        }
    }
    const cancelOption = () => {
        setOpen(false)
      
      }

      const handleNext = () => {
        setCurrentIndex(prevIndex => prevIndex + 1);
    };


    useEffect(() => {
        setIsLoading(true)
        getAll()
    }, [])

    return (
        <>
            {isLoading ?
                <Loader />
                :
                <>
                    <Toptag />

                    <BackPaper>

                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item style={{ fontSize: "20px", fontWeight: "400", color: "grey" }}>
                                Technician Profile Details
                            </Grid>
                        </Grid>

                        <hr></hr>

                        <Grid container >
                            <Grid xs={12} sm={12} md={6} lg={5} item >

                                {/* <Grid container spacing={3} direction="column" justifyContent="flex-start" alignItems="center"> */}
                                <Grid item>
                                    {
                                        <img src={obj.profile_pic && obj.profile_pic !== "" ? `${obj.profile_pic}` : '/images/blank_pic.png'}
                                            alt=""
                                            className='imgCont1' />
                                    }
                                    {/* <SwipeableTextMobileStepper licImg={obj.certificates} /> */}
                                </Grid>
                                {/* </Grid> */}

                            </Grid>

                            <Grid xs={12} sm={12} md={6} lg={7} item>
                                <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="flex-start">

                                    <Grid item>Name</Grid>
                                    <Grid item style={{ width: "100%" }}>
                                        <input type="text" disabled={!edit} value={obj.user_name} name="user_name" id="user_name" onChange={inputEvent} className="detailBarInput" />
                                    </Grid>

                                    <Grid item>Email</Grid>
                                    <Grid item style={{ width: "100%" }}>
                                        <input type="text" disabled={!edit} value={obj.email} name="email" id="email" onChange={inputEvent} className="detailBarInput" />
                                    </Grid>

                                    <Grid item>Contact</Grid>
                                    <Grid item style={{ width: "100%" }}>
                                        <input type="text" disabled={!edit} value={obj.contact !== "" ? `${obj.country_code} ${obj.contact}` : "N/A"} name="contact" id="contact" onChange={inputEvent} className="detailBarInput" />
                                    </Grid>

                                    <Grid item>Address</Grid>
                                    <Grid item style={{ width: "100%" }}>
                                        <input type="text" disabled={!edit} value={obj.address !== "" ? `${obj.address}` : "N/A"} name="contact" id="contact" onChange={inputEvent} className="detailBarInput" />
                                    </Grid>

                                    <Grid item>Certificate Status</Grid>
                                    <Grid item style={{ width: "100%",display: 'flex', alignItems: 'center' }}>
                                        {obj.certificates && obj.certificates.length>0 ? (
                                            <>
                                            <div className='purchaseChip'>
                                            <FileCopyOutlined style={{ color: "green" }} />
                                            <div>Available</div>
                                        </div>
                                            
                                            <div>
                                                <span onClick={() => setOpen(true)} style={{color: 'blue', textDecoration: 'underline',cursor:'pointer',marginLeft: '10px'}}>view</span></div>
                                            </>
                                        ) : (
                                            <div className='purchaseChip'>
                                            <AssignmentLateOutlined style={{ color: "red" }} />
                                            <div>Not available</div>
                                        </div>
                                        )}
                                    </Grid>

                                    <Grid item>Status</Grid>
                                    <Grid item style={{ width: "100%" }}>
                                        {obj.isAccDeleted === true ?
                                            <div className='purchaseChip'>
                                                <Block style={{ color: "red" }} />
                                                <div>Inactive</div>
                                            </div>
                                            :
                                            <div className='purchaseChip'>
                                                <Person style={{ color: "grey" }} />
                                                <div>Active</div>
                                            </div>
                                        }
                                    </Grid>
                                    <Grid item>Email Verification Status</Grid>
                                    <Grid item style={{ width: "100%" }}>
                                        {obj.emailVerified === true ?
                                            <div className='purchaseChip'>
                                                <VerifiedOutlined style={{ color: "green" }} />
                                                <div>Verfied</div>
                                            </div>
                                            :
                                            <div className='purchaseChip'>
                                                <GppMaybeOutlined style={{ color: "red" }} />
                                                <div>Not Verfied</div>
                                            </div>
                                        }
                                    </Grid>
                                   

                                    {/* <Grid item>Contact Verified</Grid>
                  <Grid item style={{ width: "100%" }}>
                    {obj.contactVerified === true ?
                      <div className='purchaseChip'>
                        <VerifiedOutlined style={{ color: "green" }} />
                        <div>Verfied</div>
                      </div>
                      :
                      <div className='purchaseChip'>
                        <GppMaybeOutlined style={{ color: "red" }} />
                        <div>Not Verfied</div>
                      </div>
                    }
                  </Grid>
                  <Grid item>Contact</Grid>
                  <Grid item style={{ width: "100%" }}>
                    <input type="text" disabled value={obj.country_code + obj.contact}  className="detailBarInput" />
                  </Grid> */}
                                </Grid>
                            </Grid>
                        </Grid>

                    </BackPaper>

                    {/* Dialog for view certificates */}
                    <Dialog open={open}>
              <DialogTitle style={{ width: '600px' }}>Certificates</DialogTitle>
              <DialogContent>
               
                <Grid xs={12} sm={12} md={6} lg={3} item>
                    <Grid container spacing={2} direction="row" justifyContent="space-evenly" alignItems="center" >
                      <Grid item>
                      <TextMobileStepper ImgArr={obj.certificates}/>
                        {/* <img src={image == "" ? '/images/vehicle_blank.png' : image} style={{ width: '200px', height: '150px' }} alt="selected_image" className="imgCont1" /> */}
                        {/* {obj.certificates && obj.certificates.length > 0 && (
                <Grid item>
                    <img
                        style={{ height: '600px', width: '600px' }}
                        src={obj.certificates[currentIndex]}
                        alt=""
                    />
                    {console.log(currentIndex, 'currind')}
                    {currentIndex < obj.certificates.length && (
                        <button onClick={ handleNext}>Next</button>
                    )} */}
                {/* </Grid> */}
            {/* )} */}
                      </Grid>
                  </Grid>
                  </Grid>

                 




              </DialogContent>
              <DialogActions>
                <Button onClick={cancelOption} >Cancel</Button>
              </DialogActions>
            </Dialog>
                </>}

        </>
    )
}

export default TechnicianDetail