import React from "react";
import "./sidebar.css";

import { NavLink } from "react-router-dom";

import {
  TipsAndUpdates,
  PostAdd,
  Gavel,
  PrivacyTip,
  ArchiveOutlined,
  PeopleAltOutlined,
  HomeOutlined,
  Quiz,
  MovieFilter,
  Article,
  Group,
  ContactMail,
  TimeToLeave,
  EngineeringOutlined,
} from "@mui/icons-material";

export default function Sidebar() {
  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Dashboard</h3>
          <ul className="sidebarList">
            <NavLink to="/home" className="link">
              <li className="sidebarListItem">
                <HomeOutlined color="action" className="sidebarIcon " />
                Home
              </li>
            </NavLink>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">User Management</h3>
          <ul className="sidebarList">
            <NavLink to="/users" className="link">
              <li className="sidebarListItem">
                <Group color="action" className="sidebarIcon" />
                USERS
              </li>
            </NavLink>
            <NavLink to="/technicians" className="link">
              <li className="sidebarListItem">
                <EngineeringOutlined color="action" className="sidebarIcon" />
                TECHNICIANS
              </li>
            </NavLink>
            <NavLink to="/deleted-users" className="link">
              <li className="sidebarListItem">
                <ArchiveOutlined color="action" className="sidebarIcon" />
                DELETED ACCOUNTS
              </li>
            </NavLink>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Vehicle Management</h3>
          <ul className="sidebarList">
            <NavLink to="/vehicles" className="link">
              <li className="sidebarListItem">
                <TimeToLeave color="action" className="sidebarIcon" />
                VEHICLES
              </li>
            </NavLink>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Question Management</h3>
          <ul className="sidebarList">
            <NavLink to="/trivia-questions" className="link">
              <li className="sidebarListItem">
                <Quiz color="action" className="sidebarIcon" />
                TRIVIA QUESTIONS
              </li>
            </NavLink>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Service Guide Management</h3>
          <ul className="sidebarList">
            <NavLink to="/service-guide" className="link">
              <li className="sidebarListItem">
                <Article color="action" className="sidebarIcon" />
                SERVICE GUIDE
              </li>
            </NavLink>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Tips Management</h3>
          <ul className="sidebarList">
            <NavLink to="/tips" className="link">
              <li className="sidebarListItem">
                <TipsAndUpdates color="action" className="sidebarIcon" />
                TIPS FOR YOU
              </li>
            </NavLink>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Videos Management</h3>
          <ul className="sidebarList">
            <NavLink to="/videos" className="link">
              <li className="sidebarListItem">
                <MovieFilter color="action" className="sidebarIcon" />
                HOW TO GUIDE
              </li>
            </NavLink>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Contact Management</h3>
          <ul className="sidebarList">
            <NavLink to="/contactus" className="link">
              <li className="sidebarListItem">
                <ContactMail color="action" className="sidebarIcon" />
                CONTACTUS QUERIES
              </li>
            </NavLink>
          </ul>
        </div>
       
       
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Content</h3>
          <ul className="sidebarList">
            <NavLink to="/disclaimer" className="link">
              <li className="sidebarListItem">
                <PostAdd  color="action" className="sidebarIcon" />
                DISCLAIMER
              </li>
            </NavLink>
            <NavLink to="/terms-&-conditions" className="link">
              <li className="sidebarListItem">
                <Gavel color="action" className="sidebarIcon" />
                TERMS & CONDITIONS
              </li>
            </NavLink>
            <NavLink to="/privacy-policy" className="link">
              <li className="sidebarListItem">
                <PrivacyTip color="action" className="sidebarIcon" />
                PRIVACY POLICY
              </li>
            </NavLink>
          </ul>
        </div>
      </div>
    </div>
  );
}
