import Home from './pages/home/Home';
import Profile from './pages/profile/Profile';
import ChangePass from './pages/profile/ChangePass';
import UserDetail from './pages/user/UserDetail';
import User from './pages/user/User';
import Contact from './pages/contact/Contact';
import ContactDet from './pages/contact/ContactDet';
import Terms from './pages/content/Terms';
import Privacy from './pages/content/Privacy';
import Deleted from './pages/user/Deleted';
import Question from './pages/questionmanagement/Question';

import ContactOptions from './pages/contact/ContactOptions';
import Desclaimer from './pages/content/Desclaimer';
import QueDetails from './pages/questionmanagement/QueDetails';
import ServiceGuide from './pages/serviceguide/ServiceGuide';
import AddServiceGuide from './pages/serviceguide/AddServiceGuide';
import EditServiceGuide from './pages/serviceguide/EditServiceGuide';
import Tips from './pages/tips/Tips';
import AddTips from './pages/tips/AddTips';
import EditTips from './pages/tips/EditTips';
import Videos from './pages/videos/Videos';
import AddVideos from './pages/videos/AddVideos';
import ViewVideos from './pages/videos/ViewVideos';
import EditVideos from './pages/videos/EditVideos';
import Vehicle from './pages/vehicles/Vehicle';
import VehicleDetail from './pages/vehicles/VehicleDetail';
import Technician from './pages/user/Technician';
import TechnicianDetail from './pages/user/TechnicianDetail';




export default  [
    { path: "/" ,  Component: <Home/> },
    { path: "/profile" ,    Component: <Profile/> },
    { path: "/change-password" ,    Component: <ChangePass/> },
    { path: "/users" ,    Component: <User/> },
    { path: "/technicians" ,    Component: <Technician/> },
    { path: "/home/users" ,  Component: <User/> },
    { path: "/users/details" ,   Component: <UserDetail/> },
    { path: "/technician/details" ,   Component: <TechnicianDetail/> },
    { path: "/deleted-users" ,    Component: <Deleted/> },
    { path: "/deleted-users/details" ,   Component: <UserDetail/> },
    { path: "/trivia-questions" ,  Component: <Question/> },
    { path: "/trivia-questions/details" ,  Component: <QueDetails/> },
    { path: "/service-guide" ,  Component: <ServiceGuide/> },
    { path: "/service-guide/edit" ,  Component: <EditServiceGuide/> },
    { path: "/service-guide/add-service-guide" ,  Component: <AddServiceGuide/> },
    { path: "/tips" ,  Component: <Tips/> },
    { path: "/tips/add-tips" ,  Component: <AddTips/> },
    { path: "/tips/edit" ,  Component: <EditTips/> },
    { path: "/videos" ,   Component: <Videos/> },
    { path: "/videos/add-videos" ,   Component: <AddVideos/> },
    { path: "/videos/details" ,   Component: <ViewVideos/> },
    { path: '/videos/edit' ,   Component: <EditVideos/> },
    { path: "/home/contactus" ,    Component: <Contact/> },
    { path: "/vehicles" ,    Component: <Vehicle/> },
    { path: "/vehicles/details" ,    Component: <VehicleDetail/> },
    { path: "/contactus" ,    Component: <Contact/> },
    { path: "/contactus/details" ,   Component: <ContactDet/> },
    { path: "/contact-options" ,   Component: <ContactOptions/> },
    { path: "/disclaimer" ,   Component: <Desclaimer/> },
    { path: "/terms-&-conditions" ,   Component: <Terms/> },
    { path: "/privacy-policy" ,   Component: <Privacy/> },
    

    { path: "*" ,  Component: <Home/> },
    // { path: "/login-account" ,  Component: <Login /> },

]
