
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "../serviceguide/Toolbar";
import "react-quill/dist/quill.snow.css";
import Toptag from "../../components/topTag/Toptag";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Grid, Paper, styled } from "@mui/material";
import APIFunctions from "../../ApiFunction";
import axios from "axios";



const BackPaper = styled(Paper)({
    height: "80vh",
    overflow: "auto",
    padding: "10px",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const NavPaper = styled(Paper)({
    padding: "5px",
    borderRadius: "0",
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})


const AddTips = () => {
    const [state, setState] = useState(' ');
    const [id, setId] = useState('')
    const navigate = useNavigate();
    const [putObjurl, setPutObjurl] = useState({ url: '', key: '' });

    const [type, setType] = useState(3)
    const [disable, setDisable] = useState(false);
    const [img, setImg] = useState("")

    const limitImageWidth = () => {
        const quillContainer = document.getElementById('quillState');
        if (quillContainer) {
            const images = quillContainer.querySelectorAll('img'); // Find all images in the content
            console.log(images)
            images.forEach((image) => {
                console.log(image.width, 'image')
                // if (image.width > 340) {
                image.style.maxWidth = '340px'; // Limit the width of the image to 340px
                image.style.height = 'auto'; // Maintain aspect ratio
                // }
            });
        }
    };
    const handleChange = data => {
        setState(data)
        // limitImageWidth()
    };
    const getPutObjUrl = async (contentType) => {
        const result = await APIFunctions.putObjectUrl(contentType);
        setPutObjurl(result.data.result)
    }
    const imageChange = (e) => {
        getPutObjUrl(e.target.files[0].type); // getting the url here
        setImg(e.target.files[0]);
    };


    const updateContent = async () => {
        try {
            if (img == "") { alert("Please select banner image"); return; }
            if (!state) { alert("Some content needs to be added."); return; }
            let data = {
                pic: putObjurl.key,
                content: state
            }
            console.log(data, "data tip")
            if (img !== "") {
                const presignedURL = putObjurl.url;
                await axios.put(presignedURL, img, {
                    headers: {
                        'Content-Type': img.type // Set the content type based on the file's type
                    }
                });
            }
            setDisable(true)
            let result = await APIFunctions.addTips(data);
            if (result.data.code === 200) {
                toast.success("Successfully Added!")
                setDisable(false)
            } else if (result.data.code === 203) {
                toast.info("Session Expired. Please login again!!");
                localStorage.clear();
                navigate("/")
                window.location.reload();
            }
        } catch (err) {
            console.log(err.message)
        }
    }



    return (
        <div className='container' >
            <Toptag />

            <NavPaper>
                <div style={{ display: "flex", justifyContent: "flex-end", }}>

                    <Grid item>
                        <button className="newBtn" onClick={updateContent} >SAVE</button>
                    </Grid>

                </div>
            </NavPaper>

            <BackPaper>

                <div style={{ display: "flex", justifyContent: "space-between", }}>

                    <Grid container spacing={2} direction="column" justifyContent="flex-start" >
                        <Grid item>
                            <div style={{ fontSize: "17px", fontWeight: "500", color: "black" }}> Banner Image </div>
                            <input type="file" onChange={(e) => imageChange(e)} id="imagechange" />
                        </Grid>


                        <Grid item>
                            <div style={{ fontSize: "17px", fontWeight: "500", color: "black" }}> Content</div>
                            <EditorToolbar />
                            <ReactQuill
                                style={{ height: '80vh', overflow: "auto" }}
                                theme="snow"
                                id='quillState'
                                value={state}
                                onChange={handleChange}
                                placeholder={"It is recommended to add small size of pictures in the content..."}
                                modules={modules}
                                formats={formats}
                            />

                        </Grid>
                    </Grid>

                </div>



            </BackPaper>
        </div>
    )
}

export default AddTips