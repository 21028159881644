import { AddToPhotos, Block, DeleteOutline, DeleteOutlined, CheckCircle, GppMaybeOutlined, Person, PhotoOutlined, VerifiedOutlined, WorkspacePremium } from '@mui/icons-material'
import { Chip, Grid, Paper, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import APIFunctions from '../../ApiFunction'
import Toptag from '../../components/topTag/Toptag';
import Loader from '../../components/loader/Loader'
import { BASE_URL, Img_url } from '../../Api'

const BackPaper = styled(Paper)({
    height: "80vh",
    borderRadius: "0",
    padding: "20px",
    overflow: "auto",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})


const QueDetails = () => {
    const id = localStorage.getItem('que_id');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [edit, setEdit] = useState(false)
    const [disable, setDisable] = useState(false)
    const [obj, setObj] = useState(
        { pic: "", que: "", options: []}
    )

    const inputEvent = (e) => {
        setObj({
            ...obj,
            [e.target.name]: e.target.value
        });
    };


    const getAll = async () => {
        const result = await APIFunctions.getTriviaQueDetail(id);
        if (result.data.code === 200) {
            console.log(result.data.result)
            setObj(result.data.result)
            setIsLoading(false)
        } else if (result.data.code === 201) {
            toast.error("error are coming for fetching images")
        } else if (result.data.code === 203) {
            window.location.reload()
            localStorage.clear();
            navigate("/")
            toast.info("Session Expired Please login again!!")
        }
    }

    const updateUser = async () => {
        let data = {
            id: id,
            user_name: obj.user_name,
            email: obj.email,
            contact: obj.contact
        }
        setDisable(true)
        const result = await APIFunctions.updateUserDetails(data);
        if (result.data.code === 200) {
            setIsLoading(false)
            setEdit(false)
            toast.success("User update successfully!")
            setDisable(true)
        } else if (result.data.code === 201) {
            toast.error(result.data.message)
            setDisable(false)
        } else if (result.data.code === 203) {
            toast.info("Session Expired. Please login again!!");
            localStorage.clear();
            navigate("/")
            window.location.reload();
        }
    }


    useEffect(() => {
        setIsLoading(true)
        getAll()
    }, [])

    return (
        <>
            {isLoading ?
                <Loader />
                :
                <>
                    <Toptag />
                    <ToastContainer autoClose={1000} />
                    <BackPaper>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item style={{ fontSize: "20px", fontWeight: "400", color: "grey" }}>
          Question Details
        </Grid>
      </Grid>
      <hr />

      <Grid container>
        <Grid xs={12} sm={12} md={6} lg={4} item>
          <Grid item>
            <img
              src={obj.pic ? obj.pic : '/images/vehicle_blank.png'}
              alt=""
              className='imgCont1'
            />
          </Grid>
        </Grid>

        <Grid xs={12} sm={12} md={6} lg={8} item>
          <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="flex-start">
            <Grid item>Question</Grid>
            <Grid item style={{ width: "80%" }}>
              <textarea type="text" disabled={true} value={obj.que} className="detailBarInput" />
            </Grid>

            {obj.options.map((option, index) => (
              <Grid key={option._id} item style={{ width: "80%" }}>
                Option {index + 1}
                {/* <div style={{ display: 'flex', alignItems: 'center' }}> */}
                <input
                  type="text"
                  disabled={true}
                  value={option.option}
                  className="detailBarInput"
                />
                  {option.value && <CheckCircle style={{ color: 'green' }} />}
                {/* </div> */}
              </Grid>
            ))}

           
          </Grid>
        </Grid>
      </Grid>
    </BackPaper>
                </>}

        </>
    )
}

export default QueDetails